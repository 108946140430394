import { GET_CART, CARTS_ERROR, TOGGLE_CART } from "../types/cart_type";

// Get posts
export const getCart = () => async (dispatch) => {
  try {
    const cart = localStorage.getItem(process.env.REACT_APP_CART);
    if (cart) {
      dispatch({
        type: GET_CART,
        payload: JSON.parse(cart),
      });
    } else {
      dispatch({
        type: GET_CART,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: CARTS_ERROR,
      payload: err,
    });
  }
};
export const addToCartAction = (product) => async (dispatch) => {
  try {
    const newCart = localStorage.getItem(process.env.REACT_APP_CART);
    const cart = JSON.parse(newCart);

    if (cart) {
      const filterProduct = cart.filter((item) => item.slug === product.slug);

      if (filterProduct.length > 0) {
        const newProducts = cart.map((item) => {
          if (item.slug === product.slug) {
            item.quantity = item.quantity + 1;
          }
          return item;
        });
        localStorage.setItem(
          process.env.REACT_APP_CART,
          JSON.stringify(newProducts)
        );
      } else {
        product.quantity = 1;
        localStorage.setItem(
          process.env.REACT_APP_CART,
          JSON.stringify([...cart, product])
        );
      }
    } else {
      product.quantity = 1;
      localStorage.setItem(
        process.env.REACT_APP_CART,
        JSON.stringify([product])
      );
    }
    dispatch(getCart());
  } catch (err) {
    dispatch({
      type: CARTS_ERROR,
      payload: err,
    });
  }
};

export const decreaseQuantity = (slug) => async (dispatch) => {
  try {
    const newCart = localStorage.getItem(process.env.REACT_APP_CART);
    const cart = JSON.parse(newCart);
    if (cart) {
      const filteredItems = cart.filter(
        (item) => item.slug === slug && item.quantity === 1
      );
      if (filteredItems.length > 0) {
        dispatch(removeFromCartAction(slug));
      } else {
        const newItems = cart.map((item) => {
          if (item.slug === slug) {
            item.quantity = item.quantity - 1;
          }
          return item;
        });
        localStorage.setItem(
          process.env.REACT_APP_CART,
          JSON.stringify(newItems)
        );
      }

      dispatch(getCart());
    }
  } catch (err) {
    dispatch({
      type: CARTS_ERROR,
      payload: err,
    });
  }
};

export const removeFromCartAction = (slug) => async (dispatch) => {
  try {
    const newCart = localStorage.getItem(process.env.REACT_APP_CART);
    const cart = JSON.parse(newCart);
    if (cart) {
      const filteredItems = cart.filter((item) => item.slug !== slug);
      localStorage.setItem(
        process.env.REACT_APP_CART,
        JSON.stringify(filteredItems)
      );
    }

    dispatch(getCart());
  } catch (err) {
    dispatch({
      type: CARTS_ERROR,
      payload: err,
    });
  }
};
export const openCart = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_CART,
    payload: value,
  });
};
