import React from 'react';
import Footer from '../../components/common/footer/Footer';
import Header from '../../components/common/header/Header';
import { Link } from 'react-router-dom';

function VendorInformation() {
  return (
    <div className='vn-rp'>
      <Header />
      <div className='page-content'>
        <section className='vendor-profile-banner'>
          <div className='container'>
            <div className='row border-vendor'>
              <div className='col-md-8'>
                <div className='profile-banner-content'>

                  <h2>Sell online to 11 Cr+ customers at <br /> 0% Commission</h2>
                  <p>
                    Become a Brown Laces seller and grow your business across
                    India
                  </p>
                  <div className="vendor-login-btn">
                    <Link to="/vendor-signup">
                      <button>Signup</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='vendor-profile-banner-image'>
                  <img src='/assets/images/undraw_investment_data_re_sh9x.svg' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='buying-option ptb-50'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='trust-box'>
                  <div className='trust-content'>
                    <h2>11 Lakh+</h2>
                    <h5>Trust Brown Laces to sell online</h5>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='trust-box'>
                  <div className='trust-content'>
                    <h2>14 Crore+</h2>
                    <h5>Customers buying across India</h5>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='trust-box'>
                  <div className='trust-content'>
                    <h2>28000+</h2>
                    <h5>Pincode Supported for delivery</h5>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='trust-box'>
                  <div className='trust-content'>
                    <h2>700+</h2>
                    <h5>Categories to sell online</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='product-thumb-slider section-padding ptb-50'>
          <div className='container'>
            <div className='text-center pb-3'>
              <h3 className='mb-2 h3 fw-bold'>
                Why Suppliers Love Brown Laces
              </h3>
              <p className='mb-2 text-capitalize'>
                All the benefits that come with selling on Brown Laces are
                designed
                <br />
                to help you sell more, and make it easier to grow your business.
              </p>
            </div>
            <div className='row row-cols-1 row-cols-lg-4 g-4'>
              <div className='col d-flex'>
                <div className='card depth border-0 rounded-0 border-bottom border-primary border-3 w-100'>
                  <div className='card-body text-center'>
                    <div className='h1 fw-bold my-2 text-primary'>
                      <img src='/assets/images/commission-lg.png' />
                    </div>
                    <h5 className='fw-bold'>0% Commission Fee</h5>
                    <p className='mb-0'>
                      Suppliers selling on Brown Laces keep 100% of their profit
                      by not paying any commission
                    </p>
                  </div>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='card depth border-0 rounded-0 border-bottom border-danger border-3 w-100'>
                  <div className='card-body text-center'>
                    <div className='h1 fw-bold my-2 text-danger'>
                      <img src='/assets/images/file.png' />
                    </div>
                    <h5 className='fw-bold'>0 Penalty Charges</h5>
                    <p className='mb-0'>
                      Sell online without the fear of order cancellation charges
                      with 0 Penalty for late dispatch or order cancellations.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='card depth border-0 rounded-0 border-bottom border-success border-3 w-100'>
                  <div className='card-body text-center'>
                    <div className='h1 fw-bold my-2 text-success'>
                      <img src='/assets/images/growth.png' />

                    </div>
                    <h5 className='fw-bold'>Growth for Every Supplier</h5>
                    <p className='mb-0'>
                      From small to large and unbranded to branded, all
                      suppliers have grown their businesses on Brown Laces
                    </p>
                  </div>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='card depth border-0 rounded-0 border-bottom border-warning border-3 w-100'>
                  <div className='card-body text-center'>
                    <div className='h1 fw-bold my-2 text-warning'>
                      <img src='/assets/images/deal_2.png' />
                    </div>
                    <h5 className='fw-bold'>Ease of Doing Business</h5>
                    <ul>
                      <li>Easy Product Listing</li>
                      <li>Lowest Cost Shipping</li>
                      <li>7-Day Payment Cycle from the delivery date</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='excludive ptb-50'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='supplier'>
                  <div className="supplier-inside-text">
                    <h3>Exclusive Supplier+ <br /> Rewards for the first <br /> 30 days</h3>
                  </div>
                </div>
              </div>
              <div className='col-md-7'>
                <div className='row mt-5'>
                  <div className='col-md-6'>
                    <div className='free-catelog-box'>
                      <img src='/assets/images/announcement.png' />
                      <h3>Free catalog visibility of ₹1200</h3>
                      <p>
                        Run advertisements for your catalogs to increase the
                        visibility of your products and get more orders
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='free-catelog-box '>
                      <img src='/assets/images/manager.png' />
                      <h3>Dedicated Catalog Manager</h3>
                      <p>
                        Clear all your cataloging doubts like how to upload
                        catalogs, correct quality check errors and more
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='free-catelog-box'>
                      <img src='/assets/images/cancel_3.png' />
                      <h3>Free Return Shipping</h3>
                      <p>
                        Send out your orders stress-free with zero fees on
                        return shipping for the first month
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='free-catelog-box '>
                      <img src='/assets/images/package-box.png' />
                      <h3>No Order Cancellation Charges</h3>
                      <p>
                        Cancel orders that you can’t fulfil for unforeseen
                        reasons without worrying about penalties
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=' how-w'>
          <div className='container'>
            <div class='text-center pb-3'>
              <h3 class='mb-2 h3 fw-bold'>How it works</h3>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='how-works grey-background'>
                  <div className='w-layout-grid grid-11 padding-20 d-flex'>
                    <div className='block-v4'>
                      <div className='block-v4-number'>
                        <div className='num'>1</div>
                        <div className='block-v4-line' />
                      </div>
                      <div>
                        <div className='block-v4-titlle'>Create Account</div>
                        <div className='block-v4-text'>All you need is :</div>
                        <ul role='list' className='block-v4-list'>
                          <li className='list-item-pink'>
                            <div className='grey-color'>GSTIN</div>
                          </li>
                          <li className='list-item-pink'>
                            <div className='grey-color'>Bank Account</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='block-v4'>
                      <div className='block-v4-number'>
                        <div className='num'>2</div>
                        <div className='block-v4-line' />
                      </div>
                      <div>
                        <div className='block-v4-titlle'>List Products</div>
                        <div className='block-v4-text'>
                          List the products you want to sell in your supplier
                          panel
                        </div>
                      </div>
                    </div>
                    <div className='block-v4'>
                      <div className='block-v4-number'>
                        <div className='num'>3</div>
                        <div className='block-v4-line' />
                      </div>
                      <div>
                        <div className='block-v4-titlle'>Get Orders</div>
                        <div className='block-v4-text'>
                          Start getting orders from crores of Indians actively
                          shopping on our platform.
                        </div>
                      </div>
                    </div>
                    <div className='block-v4'>
                      <div className='block-v4-number'>
                        <div className='num'>4</div>
                        <div className='block-v4-line' />
                      </div>
                      <div>
                        <div className='block-v4-titlle'>
                          Lowest Cost Shipping
                        </div>
                        <div className='block-v4-text'>
                          Products are shipped to customers at lowest costs
                        </div>
                      </div>
                    </div>
                    <div className='block-v4'>
                      <div className='block-v4-number'>
                        <div className='num'>5</div>
                      </div>
                      <div>
                        <div className='block-v4-titlle'>Receive Payments</div>
                        <div className='block-v4-text'>
                          Payments are deposited directly to your bank account
                          following a 7-day payment cycle from order delivery.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='excludive'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-5'>
                <div className='supplier'>
                  <h3>Grow Your Business With Brown Laces</h3>
                </div>
              </div>
              <div className='col-md-7'>
                <div className='row mt-5'>
                  <div className='col-md-6'>
                    <div className='free-catelog-box'>
                      <img src='/assets/images/discount.png' />
                      <h3>Lowest Shipping Costs</h3>
                      <p>
                        Sell your products across India to over 28,000+ pincodes
                        at lowest delivery cost.
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='free-catelog-box '>
                      <img src='/assets/images/advertisements.png' />
                      <h3>Ads to grow more</h3>
                      <p>
                        Use selling tools like Brown Laces Ads to be more
                        visible and sell more products
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='free-catelog-box'>
                      <img src='/assets/images/package-box.png' />
                      <h3>Next Day Dispatch Program</h3>
                      <p>
                        Sign up for the quick Next Day Dispatch (NDD) Program
                        and get higher visibility and your own Account Manager
                      </p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='free-catelog-box '>
                      <img src='/assets/images/diagram.png' />

                      <h3>Business Insights</h3>
                      <p>
                        Use product & price recommendations so that you’re
                        always on top of your business
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default VendorInformation;
