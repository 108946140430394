import React from "react";

function ProductPrice({ product, activeVariantVersion, currency }) {
  if (product && product.is_variable_product && activeVariantVersion) {
    return (
      <>
        {activeVariantVersion.price < product.regular_price && (
          <del>
            {currency}
            {product.regular_price}
          </del>
        )}
        {currency}
        {activeVariantVersion.price}
      </>
    );
  }
  return (
    <>
      {product && product.sale_price < product.regular_price && (
        <del>
          {currency}
          {product.regular_price}
        </del>
      )}
      {currency}
      {product.sale_price}
    </>
  );
}

export default ProductPrice;
