import React, { useState, useEffect } from "react";
import ProductGalleryCard from "./ProductGalleryCard";
import ProductTitle from "./ProductTitle";
import {
  useAddToWishlist,
  useCheckInWishlist,
} from "../../shared/hooks/UseWishlist";
import ProductPrice from "./ProductPrice";
import ProductOptions from "./ProductOptions";
import DiscountSection from "./DiscountSection";
import ReviewSection from "./ReviewSection";
import ReactModal from "react-modal";
import ActionButtonsCard from "./ActionButtonsCard";
import { Link } from "react-router-dom";
function ProductCard({ product }) {
  console.log("PRODUCT", product);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [activeVariation, setActiveVariation] = useState(null);
  const [activeMedia, setActiveMedia] = useState(null);
  const [activeVariantVersion, setActiveVariantVersion] = useState(null);
  const [wishlist, addToWishlist] = useAddToWishlist();
  const [productInWishlist] = useCheckInWishlist(product);

  useEffect(() => {
    // console.warn("Variations", product);
    if (product && product.variations) {
      if (!product.is_variable_product || !product.variations) {
      } else {
        if (activeVariation) {
          let filteredOptions = product.variations.filter(
            (item) => item._id == activeVariation
          );
          if (filteredOptions.length == 0) {
            setActiveVariation(filteredOptions[0]._id);
            setActiveVariantVersion(filteredOptions[0]);
          }
        } else {
          // setActiveVariation(product.variations[0]._id);
          // setActiveVariantVersion(product.variations[0]);
        }
      }
    }
  }, [product, activeVariation]);
  // useEffect(() => {
  //   if (product) {
  //     setActiveVariation(queryParams.variation);
  //   }
  // }, [queryParams.variation]);

  useEffect(() => {
    if (activeVariation && product && product.variations) {
      let selectedVariant = product.variations.filter(
        (item) => item._id == activeVariation
      );
      if (selectedVariant.length > 0) {
        setActiveVariantVersion(selectedVariant[0]);
        if (selectedVariant[0].media && selectedVariant[0].media.length > 0) {
          setActiveMedia(selectedVariant[0].media);
        } else {
          setActiveMedia(product.media);
        }
      }
    }
  }, [activeVariation, product]);

  const isEqual = (value, other) => {
    var type = Object.prototype.toString.call(value);

    if (type !== Object.prototype.toString.call(other)) return false;

    if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

    var valueLen =
      type === "[object Array]" ? value.length : Object.keys(value).length;
    var otherLen =
      type === "[object Array]" ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    var compare = function (item1, item2) {
      var itemType = Object.prototype.toString.call(item1);

      if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
        if (!isEqual(item1, item2)) return false;
      } else {
        if (itemType !== Object.prototype.toString.call(item2)) return false;

        if (itemType === "[object Function]") {
          if (item1.toString() !== item2.toString()) return false;
        } else {
          if (item1 !== item2) return false;
        }
      }
    };

    if (type === "[object Array]") {
      for (var i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) return false;
        }
      }
    }

    return true;
  };
  const checkExistence = ({ array, label, value }) => {
    if (array && label && value) {
      const filterData = array.options.filter(
        (item) => item.label == label && value == item.value
      );
      if (filterData && filterData.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleVariationChange = ({ label, value }) => {
    if (
      product.variations &&
      product.variations.length > 0 &&
      activeVariantVersion &&
      activeVariantVersion.options
    ) {
      const newVariationToMap = activeVariantVersion.options.map((item) => {
        if (item.label == label) {
          return {
            label: label,
            value: value,
          };
        } else {
          return {
            label: item.label,
            value: item.value,
          };
        }
      });
      // console.log("NEW VARIATION", newVariationToMap);
      const newVariationProductsMap = product.variations.map((item) => {
        let newAllOptions = item.options.map((newItem) => {
          return {
            label: newItem.label,
            value: newItem.value,
          };
        });
        item.options = newAllOptions;
        return item;
      });
      console.log("NEW VARIATION PRODUCTS", newVariationProductsMap);

      let finalFilterProductVariation = newVariationProductsMap.filter((item) =>
        isEqual(item.options, newVariationToMap)
      );
      // console.log("FInal Product Variation", finalFilterProductVariation);
      if (
        finalFilterProductVariation &&
        finalFilterProductVariation.length > 0
      ) {
        // let queryParams = { variation: finalFilterProductVariation[0]._id };
        // const query = qs.stringify(queryParams, {
        //   encodeValuesOnly: true,
        // });
        setActiveVariation(finalFilterProductVariation[0]._id);
        // setActiveVariantVersion(product.variations[0]);
        // history.push(`${location.pathname}?${query}`);
      }
    } else {
      if (product.variations && product.variations.length > 0) {
        console.log(label, value, product);
        const newVariationProductsMap = product.variations.map((item) => {
          let newAllOptions = item.options.map((newItem) => {
            return {
              label: newItem.label,
              value: newItem.value,
            };
          });
          item.options = newAllOptions;
          return item;
        });

        let finalFilterProductVariation = newVariationProductsMap.filter(
          (item) =>
            item.options &&
            item.options[0] &&
            item.options[0].label == label &&
            item.options[0].value == value
        );
        console.log("finalFilterProductVariation", finalFilterProductVariation);

        // let queryParams = { variation: finalFilterProductVariation[0]._id };
        // const query = qs.stringify(queryParams, {
        //   encodeValuesOnly: true,
        // });
        // history.push(`${location.pathname}?${query}`);
        setActiveVariation(finalFilterProductVariation[0]._id);
      }
    }
  };
  return (
    <>
      <div className="card">
        {" "}
        <DiscountSection
          product={product}
          activeVariantVersion={activeVariantVersion}
        />{" "}
        {/* 50% OFF{" "} */}
        <div className="position-relative overflow-hidden">
          <ActionButtonsCard
            product={product}
            activeVariantVersion={activeVariantVersion}
          />
          <Link
            to={
              product.is_variable_product && activeVariantVersion
                ? `/product/${product.slug}?variation=${
                    activeVariantVersion && activeVariantVersion._id
                  }`
                : `/product/${product.slug}`
            }
          >
            <ProductGalleryCard
              product={product}
              activeVariantVersion={activeVariantVersion}
            />
          </Link>
        </div>
        <div className="card-body">
          <div className="product-info text-center">
            <Link
              to={
                product.is_variable_product && activeVariantVersion
                  ? `/product/${product.slug}?variation=${
                      activeVariantVersion && activeVariantVersion._id
                    }`
                  : `/product/${product.slug}`
              }
            >
              <h6 className="mb-1 fw-bold product-name">
                <ProductTitle
                  product={product}
                  activeVariantVersion={activeVariantVersion}
                />
              </h6>
            </Link>

            {product.is_variable_product && (
              <div className="product-options">
                <ProductOptions
                  product={product}
                  activeVariantVersion={activeVariantVersion}
                  handleVariationChange={handleVariationChange}
                  checkExistence={checkExistence}
                  hideOptionTitle={true}
                />
              </div>
            )}

            <ReviewSection product={product} />
            <p className="mb-0 h6 fw-bold product-price">
              {" "}
              <ProductPrice
                product={product}
                activeVariantVersion={activeVariantVersion}
                currency={"₹"}
              />{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
