import React from 'react'
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Header from "../../components/common/header/Header";
import Footer from "../../components/common/footer/Footer";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { TextInput, FileUpload } from "../../components/Form/Form";
import { useRegisterUser, useRegisterVendor, useLoginUser } from "../../shared/hooks/UseAuth";

function VendorSignup() {
    const [registerVendor] = useRegisterVendor();
    const [loginUser] = useLoginUser();

    const submitFormClicked = async (values) => {
        await registerVendor(values);
        // history.push(`/`);
    };
    return (
        <div>
            <Header />
            <div className="page-content">
                <Breadcrum title={"Vendor Sign Up"} />
                <section className="section-padding vendor-signup-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
                                <div className="signup-card rounded-0">
                                    <div className="card-body p-4">
                                        <h4 className="mb-0 fw-bold text-center">Vendor Registration</h4>

                                        <Formik
                                            initialValues={{
                                                name: "",
                                                phone: "",
                                                email: "",
                                                password: "",
                                                store_name: "",
                                                store_description: "",
                                                store_logo: "",
                                                gst_no: "",
                                                gst_certificate: "",
                                            }}
                                            validationSchema={Yup.object({
                                                name: Yup.string().required("Required"),
                                                phone: Yup.string().required("Required"),
                                                email: Yup.string().required("Required"),
                                                password: Yup.string().required("Required"),

                                            })}
                                            onSubmit={async (
                                                values,
                                                { setSubmitting, resetForm }
                                            ) => {
                                                setSubmitting(true);
                                                await submitFormClicked(values);
                                                resetForm();
                                                setSubmitting(false);
                                            }}
                                        >
                                            {(formik) => {
                                                console.log(formik);
                                                return (
                                                    <Form>
                                                        <div className="row g-4">
                                                            <div className="col-12">
                                                                <TextInput
                                                                    label="Vendor Name"
                                                                    name="name"
                                                                    type="text"
                                                                />
                                                            </div>

                                                            <div className="col-12">
                                                                <TextInput
                                                                    label="Vendor Phone"
                                                                    name="phone"
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <TextInput
                                                                    label="Vendor Email"
                                                                    name="email"
                                                                    type="email"
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <TextInput
                                                                    label="Password"
                                                                    name="password"
                                                                    type="password"
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <TextInput
                                                                    label="Store Name"
                                                                    name="vendor.store_name"
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FileUpload
                                                                    title="Store Logo"
                                                                    item="vendor.store_logo"
                                                                    formik={formik}
                                                                    value={formik.values.store_logo}
                                                                    placeholder="Enter Store Logo"
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <TextInput
                                                                    label="Gst Number"
                                                                    name="vendor.gst_no"
                                                                    type="text"
                                                                    placeholder="Enter Gst Number"
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <FileUpload
                                                                    title="Gst Certificate"
                                                                    item="vendor.gst_certificate"
                                                                    formik={formik}
                                                                    value={formik.values.gst_certificate}
                                                                    placeholder="Enter Gst Certificate"
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <TextInput
                                                                    label="Store Description"
                                                                    name="vendor.store_description"
                                                                    type="html"
                                                                    placeholder="Enter Store Description"
                                                                />
                                                            </div>
                                                            <div className="col-12">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-dark rounded-0 btn-ecomm w-100"
                                                                >
                                                                    Sign Up
                                                                </button>
                                                            </div>
                                                            <div className="col-12 text-center">
                                                                <p className="mb-0 rounded-0 w-100">
                                                                    Already have an account?{" "}
                                                                    <Link to="/login" className="text-danger">
                                                                        Sign In
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end row*/}
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    )
}

export default VendorSignup