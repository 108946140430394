import React from 'react'
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";

function PaymentPolicy() {
    return (
        <div>
            <Header />
            <div className="page-content">
                <Breadcrum title={"Payment Policy"} />
                <section className='policy-page'>
                    <div className="container policy-page-content">
                        <div className="policy-page-heading">
                            Payment Policy
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <div><p><strong><u>Payment Policy</u></strong></p>
                                    <p><strong>Payments</strong></p>
                                    <p><strong>How do I pay for a Brownlaces purchase?</strong></p>
                                    <p>Brown Laces offers you multiple payment methods. Whatever your online mode of payment, you can rest assured that Brown Laces's trusted payment gateway partners use secure encryption technology to keep your transaction details confidential at all times.</p>
                                    <p>You may use Internet Banking, Gift Card, Cash on Delivery and Wallet to make your purchase.</p>
                                    <p>Brown Laces also accepts payments made using Visa, MasterCard, Maestro and American Express credit/debit cards in India.</p>
                                    <p><strong>Are there any hidden charges (Octroi or Sales Tax) when I make a purchase on Brown Laces?</strong></p>
                                    <p>There are NO hidden charges when you make a purchase on Brown Laces. The prices listed for all the items are final and all-inclusive. The price you see on the product page is exactly what you pay.</p>
                                    <p>Delivery charges may be extra depending on the seller policy. Please check individual seller for the same. In case of seller WS Retail, the ₹50 delivery charge is waived off on orders worth ₹500 and over.</p>
                                    <p><strong>What is Cash on Delivery?</strong></p>
                                    <p>If you are not comfortable making an online payment on Brown Laces.com, you can opt for the Cash on Delivery (C-o-D) payment method instead. With C-o-D you can pay in cash at the time of actual delivery of the product at your doorstep, without requiring you to make any advance payment online.</p>
                                    <p>The maximum order value for a Cash on Delivery (C-o-D) payment is ₹50,000. It is strictly a cash-only payment method. Gift Cards or store credit cannot be used for C-o-D orders. Foreign currency cannot be used to make a C-o-D payment. Only Indian Rupees accepted.</p>
                                    <p><strong>How do I pay using a credit/debit card?</strong></p>
                                    <p>We accept payments made by credit/debit cards issued in India and 21 other countries.</p>
                                    <p>Credit cards</p>
                                    <p>We accept payments made using Visa, MasterCard and American Express credit cards.</p>
                                    <p>To pay using your credit card at checkout, you will need your card number, expiry date, three-digit CVV number (found on the backside of your card). After entering these details, you will be redirected to the bank's page for entering the online 3D Secure password.</p>
                                    <p>Debit cards</p>
                                    <p>We accept payments made using Visa, MasterCard and Maestro debit cards.</p>
                                    <p>To pay using your debit card at checkout, you will need your card number, expiry date (optional for Maestro cards), three-digit CVV number (optional for Maestro cards). You will then be redirected to your bank's secure page for entering your online password (issued by your bank) to complete the payment.</p>
                                    <p>Internationally issued credit/debit cards cannot be used for Flyte, Wallet and eGV payments/top-ups.</p>
                                    <p><strong>Is it safe to use my credit/debit card on Brown Laces?</strong></p>
                                    <p>Your online transaction on Brown Laces is secure with the highest levels of transaction security currently available on the Internet. Brown Laces uses 256-bit encryption technology to protect your card information while securely transmitting it to the respective banks for payment processing.</p>
                                    <p>All credit card and debit card payments on Brown Laces are processed through secure and trusted payment gateways managed by leading banks. Banks now use the 3D Secure password service for online transactions, providing an additional layer of security through identity verification.</p>
                                    <p><strong>What steps does Brown Laces take to prevent card fraud?</strong></p>
                                    <p>Brown Laces realizes the importance of a strong fraud detection and resolution capability. We and our online payments partners monitor transactions continuously for suspicious activity and flag potentially fraudulent transactions for manual verification by our team.</p>
                                    <p>In the rarest of rare cases, when our team is unable to rule out the possibility of fraud categorically, the transaction is kept on hold, and the customer is requested to provide identity documents. The ID documents help us ensure that the purchases were indeed made by a genuine card holder. We apologise for any inconvenience that may be caused to customers and request them to bear with us in the larger interest of ensuring a safe and secure environment for online transactions.</p>
                                    <p><strong>What is a 3D Secure password?</strong></p>
                                    <p>The 3D Secure password is implemented by VISA and MasterCard in partnership with card issuing banks under the "Verified by VISA" and "Mastercard SecureCode" services, respectively.</p>
                                    <p>The 3D Secure password adds an additional layer of security through identity verification for your online credit/debit card transactions. This password, which is created by you, is known only to you. This ensures that only you can use your card for online purchases.</p>
                                    <p><strong>How can I get the 3D Secure password for my credit/debit card?</strong></p>
                                    <p>You can register for the 3D Secure password for your credit/debit card by visiting your bank's website. The registration links for some of the banks have been provided below for easy reference:</p>
                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <p><a href="https://retail.onlinesbi.com/personal/secure_card_transactions.html">State Bank of India</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="http://www.icicibank.com/aboutus/article/ivr.html">ICICI Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://netsafe.hdfcbank.com/ACSWeb/enrolljsp/Registration1.jsp">HDFC Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://acs2.enstage-sas.com/ACSWeb/EnrollWeb/PNB/main/index.jsp">Punjab National Bank</a></p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p><a href="https://secure.axisbank.com/ACSWeb/EnrollWeb/AxisBank/main/index.jsp">Axis Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://corpbank.electracard.com/corpbank/enrollment/enroll_welcome.jsp">Corporation Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://cardsecurity.enstage.com/ACSWeb/EnrollWeb/AndhraBank/main/index.jsp">Andhra Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="http://www.bankofbaroda.com/vbv.asp">Bank of Baroda</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><a href="http://www.standardchartered.co.in/personal/credit-cards/en/3d_secure.html">Standard Chartered India</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://ubi.electracard.com/ubi/enrollment/WhatisVerifyByVisa.jsp">Union Bank of India</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://cbi.electracard.com/cbi/enrollment/enroll_welcome.jsp">Central Bank of India</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://www.online.citibank.co.in/products-services/online-services/online-security.htm">Citibank</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><a href="https://www.deutschebank.co.in/direct_debit.html">Deutsche Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="http://www.dhanbank.com/personal/vbv-faq.aspx">Dhanlakshmi Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://secureonline.idbibank.com/ACSWeb/EnrollWeb/IDBIBank/main/index.jsp">IDBI Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://cardsecurity.enstage.com/ACSWeb/EnrollWeb/CanaraBank/main/termsandconditions.jsp">Canara Bank</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><a href="http://www.indusind.com/Terms-and-Conditions-for-IBL-e-Secure-service.html">IndusInd Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://cardsecurity.enstage.com/ACSWeb/EnrollWeb/FederalBank/main/index.jsp">Federal Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://cardsecurity.enstage.com/ACSWeb/EnrollWeb/KotakBank/main/init.jsp?go=2">Kotak Mahindra Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://cardsecurity.enstage.com/ACSWeb/EnrollWeb/KVB/main/vbv.jsp">Karur Vysya Bank</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><a href="https://www.obcindia.co.in/obcnew/site/inner.aspx?status=R1&amp;menu_id=16">Oriental Bank of Commerce</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://www.southindianbank.com/content/viewContentLvl2.aspx?linkIdLvl2=16&amp;linkId=920">South Indian Bank</a></p>
                                                </td>
                                                <td>
                                                    <p><a href="https://www.vijayabank.com/Card-Services/Verified-by-VISA">Vijaya Bank</a></p>
                                                </td>
                                                <td>
                                                    <p>-</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><strong>Can I use my bank's Internet Banking feature to make a payment?</strong></p>
                                    <p>Yes. Brown Laces offers you the convenience of using your bank's Internet Banking service to make a payment towards your order. With this you can directly transfer funds from your bank account, while conducting a highly secure transaction.</p>
                                    <p>We accept payment through Internet Banking for the following banks:</p>
                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <p><strong>ABN Amro Bank</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Axis Bank</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Allahabad Bank</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Andhra Bank</strong></p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>Bank of Bahrain and Kuwait</p>
                                                </td>
                                                <td>
                                                    <p>Bank of Baroda</p>
                                                </td>
                                                <td>
                                                    <p>Bank of India</p>
                                                </td>
                                                <td>
                                                    <p>Bank of Maharashtra</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Bank of Rajasthan</p>
                                                </td>
                                                <td>
                                                    <p>Central Bank of India</p>
                                                </td>
                                                <td>
                                                    <p>Citibank</p>
                                                </td>
                                                <td>
                                                    <p>City Union Bank</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Corporation Bank</p>
                                                </td>
                                                <td>
                                                    <p>Deutsche Bank</p>
                                                </td>
                                                <td>
                                                    <p>Development Credit Bank</p>
                                                </td>
                                                <td>
                                                    <p>Dhanlaxmi Bank</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Federal Bank</p>
                                                </td>
                                                <td>
                                                    <p>HDFC Bank</p>
                                                </td>
                                                <td>
                                                    <p>ICICI Bank</p>
                                                </td>
                                                <td>
                                                    <p>IDBI Bank</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>ING Vysya Bank</p>
                                                </td>
                                                <td>
                                                    <p>Indian Overseas Bank</p>
                                                </td>
                                                <td>
                                                    <p>Indian Bank</p>
                                                </td>
                                                <td>
                                                    <p>IndusInd Bank</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Jammu &amp; Kashmir Bank</p>
                                                </td>
                                                <td>
                                                    <p>Karnataka Bank</p>
                                                </td>
                                                <td>
                                                    <p>Karur Vysya Bank</p>
                                                </td>
                                                <td>
                                                    <p>Kotak Mahindra Bank</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Lakshmi Vilas Bank</p>
                                                </td>
                                                <td>
                                                    <p>Oriental Bank of Commerce</p>
                                                </td>
                                                <td>
                                                    <p>Punjab National Bank</p>
                                                </td>
                                                <td>
                                                    <p>Royal Bank of Scotland</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>South Indian Bank</p>
                                                </td>
                                                <td>
                                                    <p>Standard Chartered Bank</p>
                                                </td>
                                                <td>
                                                    <p>State Bank of Hyderabad</p>
                                                </td>
                                                <td>
                                                    <p>State Bank of India</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>State Bank of Mysore</p>
                                                </td>
                                                <td>
                                                    <p>State Bank of Travancore</p>
                                                </td>
                                                <td>
                                                    <p>Syndicate Bank</p>
                                                </td>
                                                <td>
                                                    <p>Tamilnad Mercantile Bank</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Union Bank of India</p>
                                                </td>
                                                <td>
                                                    <p>United Bank of India</p>
                                                </td>
                                                <td>
                                                    <p>Vijaya Bank</p>
                                                </td>
                                                <td>
                                                    <p>YES Bank</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><strong>Can I make a credit/debit card or Internet Banking payment on Brown Laces through my mobile?</strong></p>
                                    <p>Yes, you can make credit card payments through the Brown Laces mobile site and application. Brown Laces uses 256-bit encryption technology to protect your card information while securely transmitting it to the secure and trusted payment gateways managed by leading banks.</p>
                                    <p><strong>How does 'Instant Cashback' work?</strong></p>
                                    <p>The 'Cashback' offer is instant and exclusive to Brown Laces.com. You only pay the final price you see in your shopping cart.</p>
                                    <p><strong>How do I place a Cash on Delivery (C-o-D) order?</strong></p>
                                    <p>All items that have the "Cash on Delivery Available" icon are valid for order by Cash on Delivery.</p>
                                    <p>Add the item(s) to your cart and proceed to checkout. When prompted to choose a payment option, select "Pay By Cash on Delivery". Enter the CAPTCHA text as shown, for validation.</p>
                                    <p>Once verified and confirmed, your order will be processed for shipment in the time specified, from the date of confirmation. You will be required to make a cash-only payment to our courier partner at the time of delivery of your order to complete the payment.</p>
                                    <p><strong>What is Brown Laces's credit card EMI option?</strong></p>
                                    <p>With Brown Laces's credit card EMI option, you can choose to pay in easy installments of 3, 6, 9, 12, 18*, or 24 months* with credit cards from the following banks:</p>
                                    <ul>
                                        <li>HDFC</li>
                                        <li>Citi</li>
                                        <li>ICICI</li>
                                        <li>Kotak</li>
                                        <li>Axis</li>
                                        <li>IndusInd</li>
                                        <li>SBI</li>
                                        <li>Standard Chartered</li>
                                        <li>HSBC</li>
                                    </ul>
                                    <p>*18 &amp; 24 months EMI options are available from select banks only. Please refer to the table below for more details:</p>
                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <p>Banks</p>
                                                </td>
                                                <td>
                                                    <p>Supports 18 &amp; 24 months tenure</p>
                                                </td>
                                                <td>
                                                    <p>Minimum order value to avail 18 &amp; 24 months EMI options</p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>HDFC</p>
                                                </td>
                                                <td>
                                                    <p>Yes</p>
                                                </td>
                                                <td>
                                                    <p>₹ 10,000</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Citi</p>
                                                </td>
                                                <td>
                                                    <p>Yes</p>
                                                </td>
                                                <td>
                                                    <p>₹ 10,000</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>ICICI</p>
                                                </td>
                                                <td>
                                                    <p>Yes</p>
                                                </td>
                                                <td>
                                                    <p>₹ 10,000</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Kotak</p>
                                                </td>
                                                <td>
                                                    <p>Yes</p>
                                                </td>
                                                <td>
                                                    <p>₹ 4,000</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Axis</p>
                                                </td>
                                                <td>
                                                    <p>Yes</p>
                                                </td>
                                                <td>
                                                    <p>₹ 4,000</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>IndusInd</p>
                                                </td>
                                                <td>
                                                    <p>Yes</p>
                                                </td>
                                                <td>
                                                    <p>₹ 4,000</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>SBI</p>
                                                </td>
                                                <td>
                                                    <p>No</p>
                                                </td>
                                                <td>
                                                    <p>NA</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>Standard Chartered</p>
                                                </td>
                                                <td>
                                                    <p>Yes</p>
                                                </td>
                                                <td>
                                                    <p>₹ 4,000</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>HSBC</p>
                                                </td>
                                                <td>
                                                    <p>No</p>
                                                </td>
                                                <td>
                                                    <p>NA</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>There is NO processing fee charged for availing Brown Laces's EMI payment option. On return or exchange, interest charged by the bank till that time will not be refunded by Brown Laces.</p>
                                    <p>You may check with the respective bank/issuer on how a cancellation, refund or pre-closure could affect the EMI terms, and what interest charges would be levied on you for the same.</p>
                                    <p>Example and Calculations</p>
                                    <p>The table below shows a representative rendering of EMI plans for a Rs 20,000 purchase on Brown Laces paid using the EMI payment plan</p>
                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <p>Tenure (months)</p>
                                                </td>
                                                <td>
                                                    <p>Loan amount</p>
                                                </td>
                                                <td>
                                                    <p>Monthly installment</p>
                                                </td>
                                                <td>
                                                    <p>Bank interest rate</p>
                                                </td>
                                                <td>
                                                    <p>Total effective price you pay</p>
                                                </td>
                                                <td>
                                                    <p>Interest paid to Bank</p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>Rs 20,000</p>
                                                </td>
                                                <td>
                                                    <p>Rs 6,800.44</p>
                                                </td>
                                                <td>
                                                    <p>12.00%</p>
                                                </td>
                                                <td>
                                                    <p>Rs. 20,401.33</p>
                                                </td>
                                                <td>
                                                    <p>Rs. 401.33</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>6</p>
                                                </td>
                                                <td>
                                                    <p>Rs 20,000</p>
                                                </td>
                                                <td>
                                                    <p>Rs 3,450.97</p>
                                                </td>
                                                <td>
                                                    <p>12.00%</p>
                                                </td>
                                                <td>
                                                    <p>Rs 20,705.80</p>
                                                </td>
                                                <td>
                                                    <p>Rs. 705.80</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>Rs 20,000</p>
                                                </td>
                                                <td>
                                                    <p>Rs 2,344.32</p>
                                                </td>
                                                <td>
                                                    <p>13.00%</p>
                                                </td>
                                                <td>
                                                    <p>Rs 21,098.89</p>
                                                </td>
                                                <td>
                                                    <p>Rs. 1,098.89</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>12</p>
                                                </td>
                                                <td>
                                                    <p>Rs 20,000</p>
                                                </td>
                                                <td>
                                                    <p>Rs 1,786.35</p>
                                                </td>
                                                <td>
                                                    <p>13.00%</p>
                                                </td>
                                                <td>
                                                    <p>Rs 21,436.15</p>
                                                </td>
                                                <td>
                                                    <p>Rs. 1,436.15</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><strong>How do I make a payment using Brown Laces's credit card EMI option?</strong></p>
                                    <p>Once you've added the desired items to your Brown Laces shopping cart, proceed with your order as usual by entering your address. When you're prompted to choose a payment mode for your order, select 'EMI' &amp; follow these simple steps:</p>
                                    <ol>
                                        <li>Choose your credit-card issuing bank you wish to pay from</li>
                                        <li>Select the EMI plan of your preference</li>
                                        <li>Enter your credit card details</li>
                                        <li>Click 'Save and Pay'</li>
                                    </ol>
                                    <p>Please note that the full amount will be charged on your card the day of the transaction.</p>
                                    <p><strong><u>&nbsp;</u></strong></p></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default PaymentPolicy