import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Cart from "../../cart/Cart";
import AddressModal from "../../modal/AddressModal";
import FilterModal from "../../modal/FilterModal";
import AlertBox from "./AlertBox";

function Footer() {
  return (
    <>
      <div>
        <section className="footer-section bg-section-2 section-padding">
          <div className="container">
            <div className="row row-cols-2 row-cols-lg-4 g-4">
              <div className="col">
                <div className="footer-widget-6">
                  <Link className="navbar-brand d-none d-xl-inline" to="/">
                    <img
                      src="/assets/images/logo.png"
                      className="logo-img"
                      alt
                    />
                  </Link>

                  <h5 className="mb-3 fw-bold">About Us</h5>
                  <p className="mb-2">
                    Brown Laces allows you to walk away from the dilemma in footwear shopping and welcomes an easy relaxed way of browsing and shopping for footwear.
                  </p>
                  {/* <a className="link-dark" href="javascript:;">
                    Read More
                  </a> */}
                </div>
              </div>
              <div className="col">
                <div className="footer-widget-7">
                  <h5 className="mb-3 fw-bold">Explore</h5>
                  <ul className="widget-link list-unstyled">
                    <li>
                      <a href="javascript:;">Fashion</a>
                    </li>
                    <li>
                      <a href="javascript:;">Women</a>
                    </li>
                    <li>
                      <a href="javascript:;">Furniture</a>
                    </li>
                    <li>
                      <a href="javascript:;">Shoes</a>
                    </li>
                    <li>
                      <a href="javascript:;">Topwear</a>
                    </li>
                    <li>
                      <a href="javascript:;">Brands</a>
                    </li>
                    <li>
                      <a href="javascript:;">Kids</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-widget-8">
                  <h5 className="mb-3 fw-bold">Company</h5>
                  <ul className="widget-link list-unstyled">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>

                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/return-policy">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="/shipping-details">Shipping Details</Link>
                    </li>
                    <li>
                      <Link to="/payments-policy">Payment Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/become-seller">Become Seller</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQ's</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-widget-9">
                  <h5 className="mb-3 fw-bold">Follow Us</h5>
                  <div className="social-link d-flex align-items-center gap-2">
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100093146559577&mibextid=ZbWKwL">
                      <i className="bi bi-facebook" />
                    </a>
                    <a target="_blank" href="javascript:;">
                      <i className="bi bi-twitter" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/in/brown-laces-876b79278">
                      <i className="bi bi-linkedin" />
                    </a>
                    <a target="_blank" href="https://www.youtube.com/@brownlaces_">
                      <i className="bi bi-youtube" />
                    </a>
                    <a target="_blank" href="https://instagram.com/brownlaces_?igshid=NGExMmI2YTkyZg==">
                      <i className="bi bi-instagram" />
                    </a>
                  </div>
                  <div className="mb-3 mt-3">
                    <h5 className="mb-0 fw-bold">Support</h5>
                    <p className="mb-0 text-muted">brownlacespvtltd@gmail.com</p>
                  </div>
                  <div className>
                    <h5 className="mb-3 mt-3 fw-bold">Address</h5>
                    <p className="mb-0 text-muted">Block-A, 623 ground floor, Jwala Puri, Camp no.4, Delhi-110087</p>
                  </div>
                  <div className>
                    <h5 className="mmb-3 mt-3 fw-bold">Toll Free</h5>
                    <p className="mb-0 text-muted">9560776260</p>
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
            <div className="my-5" />
            {/* <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h5 className="fw-bold mb-3">Download Mobile App</h5>
                </div>
                <div className="app-icon d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2">
                  <div>
                    <a href="javascript:;">
                      <img
                        src="assets/images/play-store.webp"
                        width={160}
                        alt
                      />
                    </a>
                  </div>
                  <div>
                    <a href="javascript:;">
                      <img
                        src="assets/images/apple-store.webp"
                        width={160}
                        alt
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/*end row*/}
          </div>
          <div className="fixed">
            <div className="test">
              <Link to="/vendor-information">
                <button id="rotate" class="button-31" role="button">Become a Seller</button>
              </Link>
            </div>
          </div>
        </section>
        {/*end footer*/}
        <footer className="footer-strip text-center py-3 bg-section-2 border-top positon-absolute bottom-0">
          <p className="mb-0 text-muted">
            © 2023. Brownlaces.com | All rights reserved.
          </p>
        </footer>

        {/*end quick view*/}
        {/*Start Back To Top Button*/}
        <a href="javaScript:;" className="back-to-top">
          <i className="bi bi-arrow-up" />
        </a>
      </div>
      <AlertBox />
    </>
  );
}

export default Footer;
