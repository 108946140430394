import React, { useState } from 'react'
import Rating from 'react-rating'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import Filter from '../../components/filter/Filter'
import ProductCard from '../../components/product/ProductCard'

function VendorStore() {
  const [grid, setGrid] = useState(4)
  return (
    <div>
      <Header />
      <div className="page-content">
        <div className="vendor-banner"></div>
        <section className="main-shop-section">
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-shop-flex">
                    <div>
                      <img src="/assets/images/vendor/shop_icon.jpg"></img>
                    </div>
                    <div className="shop-name">
                      <h2>Brown Laces Enterprises</h2>
                      <div className="product-flex">
                        <div>
                          <h5>
                            <span className="numberr">97</span> Products
                          </h5>
                        </div>
                        <div>
                          <h5>
                            <span className="number">4.5 Ratings </span>
                            <Rating
                              initialRating={4.5}
                              emptySymbol="fa fa-star-o empty "
                              fullSymbol="fa fa-star full"
                            />
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="height-240"></div>
        <section className="py-4">
          <div className="container">
            <div className=" pb-3">
              <h3 className="mb-0 h3 fw-bold">Featured Collections</h3>
            </div>

            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-4">
              <div className="col">
                <div className="vendor-image">
                  <img src="/assets/images/vendor/NEW_ARRIVALS.png"></img>
                </div>
              </div>
              <div className="col">
                <div className="vendor-image">
                  <img src="/assets/images/vendor/HIGH_QUALITY.png"></img>
                </div>
              </div>
              <div className="col">
                <div className="vendor-image">
                  <img src="/assets/images/vendor/BEST_SELLING.png"></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-4">
          <h5 className="mb-0 fw-bold d-none">Product Grid</h5>
          <div className="container">
            <div
              className="btn btn-dark btn-ecomm d-xl-none position-fixed top-50 start-0 translate-middle-y"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbarFilter"
            >
              <span>
                <i className="bi bi-funnel me-1" /> Filters
              </span>
            </div>
            <div className="row">
              <div className="col-12 col-xl-3 filter-column">
                <Filter />
              </div>
              <div className="col-12 col-xl-9">
                <div className="shop-right-sidebar">
                  <div className="card rounded-0">
                    <div className="card-body p-2">
                      <div className="d-flex align-items-center justify-content-between bg-light p-2">
                        <div className="product-count">657 Items Found</div>
                        <div className="view-type hstack gap-2 d-none d-xl-flex">
                          <p className="mb-0">Grid</p>
                          <div>
                            <a
                              onClick={() => {
                                setGrid(3)
                              }}
                              className={
                                grid == 3
                                  ? 'grid-type-3 d-flex gap-1 active'
                                  : 'grid-type-3 d-flex gap-1 '
                              }
                            >
                              <span />
                              <span />
                              <span />
                            </a>
                          </div>
                          <div>
                            <a
                              onClick={() => {
                                setGrid(4)
                              }}
                              className={
                                grid == 4
                                  ? 'grid-type-3 d-flex gap-1 active'
                                  : 'grid-type-3 d-flex gap-1 '
                              }
                            >
                              <span />
                              <span />
                              <span />
                              <span />
                            </a>
                          </div>
                        </div>
                        <form>
                          <div className="input-group">
                            <span className="input-group-text bg-transparent rounded-0 border-0">
                              Sort By
                            </span>
                            <select className="form-select rounded-0">
                              <option selected>Whats'New</option>
                              <option value={1}>Popularity</option>
                              <option value={2}>Better Discount</option>
                              <option value={3}>Price : Hight to Low</option>
                              <option value={4}>Price : Low to Hight</option>
                              <option value={5}>Custom Rating</option>
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="product-grid mt-4">
                    <div
                      className={
                        grid == 4
                          ? 'row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4'
                          : 'row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4'
                      }
                    >
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                      <ProductCard />
                    </div>
                    {/*end row*/}
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default VendorStore
