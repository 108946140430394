import React from 'react'
import Brand from '../../components/brand/Brand'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'

function About({ title }) {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrum title={'About us'} />
        <section className="section-padding">
          <div className="container">
            <div className="row g-4">
              <div className="col-12 col-xl-6">
                <h3 className="fw-bold">Our Story</h3>
                <p>What is Brown Laces?</p>
                <p>Brown Laces is India&rsquo;s largest online footwear store and accessories store. With over 18,000 products and over a 200 brands in our catalogue you will find everything you are looking for. Right from shoes of each type such as loafers, boots, belly, formal, casual, sandals, chappals, accessories, etc. we have it all for both types of gender male and female.<br />Choose from a wide range of options in every category, exclusively handpicked to help you find the best quality available at the lowest prices. Select at your choice and your order will be delivered right to your doorstep, anywhere in all cities of Bharat Desh. You can pay online using your debit / credit card or by cash on delivery.<br />We guarantee on time delivery, and the best quality offered to you at our platform.</p>

                <p>Why should I choose Brown Laces?</p>
                <p>Brown Laces allows you to walk away from the dilemma in footwear shopping and welcomes an easy relaxed way of browsing and shopping for footwear. Discover new products and shop for all your footwear fashion needs from the comfort of your home or office or anywhere you want. No more getting stuck in traffic jams, paying for parking, standing in long queues and carrying heavy bags &ndash; get everything you need, when you need, right at your doorstep. Online shopping for footwear is now easy as every product on your monthly shopping list, is now available online at Brown Laces, India&rsquo;s best online footwear store.</p>

              </div>
              <div className="col-12 col-xl-6">
                <img
                  src="https://images.pexels.com/photos/7679877/pexels-photo-7679877.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  className="img-fluid"
                  alt
                />
              </div>
            </div>
            {/*end row*/}
            <div className="separator section-padding">
              <div className="line" />
              <h3 className="mb-0 h3 fw-bold">Why Choose Us</h3>
              <div className="line" />
            </div>
            <div className="row row-cols-1 row-cols-xl-3 g-4 why-choose">
              <div className="col d-flex">
                <div className="card rounded-0 shadow-none w-100">
                  <div className="card-body">
                    <img
                      src="assets/images/icons/delivery.webp"
                      width={60}
                      alt
                    />
                    <h5 className="my-3 fw-bold">Free Shipping</h5>
                    <p className="mb-0">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industr in
                      some form.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col d-flex">
                <div className="card rounded-0 shadow-none w-100">
                  <div className="card-body">
                    <img
                      src="assets/images/icons/money-bag.webp"
                      width={60}
                      alt
                    />
                    <h5 className="my-3 fw-bold">100% Back Gaurantee</h5>
                    <p className="mb-0">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industr in
                      some form.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col d-flex">
                <div className="card rounded-0 shadow-none w-100">
                  <div className="card-body">
                    <img
                      src="assets/images/icons/support.webp"
                      width={60}
                      alt
                    />
                    <h5 className="my-3 fw-bold">Online Support 24/7</h5>
                    <p className="mb-0">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industr in
                      some form.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Brand />
        <Footer />
      </div>
    </div>
  )
}

export default About
