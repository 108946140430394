import React from "react";
import AccountSidebar from "../../components/account/AccountSidebar";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import OrderCard from "../../components/orders/OrderCard";
import { useAllOrders } from "../../shared/hooks/UseOrder";

function AccountOrders() {
  const [data, setPageNumber] = useAllOrders();
  const { orders, orders_loading } = data;
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrum title={"Orders"} />
        <section className="section-padding">
          <div className="container">
            <div className="d-flex align-items-center px-3 py-2 border mb-4">
              <div className="text-start">
                <h4 className="mb-0 h4 fw-bold">Account - Orders</h4>
              </div>
            </div>
            <div
              className="btn btn-dark btn-ecomm d-xl-none position-fixed top-50 start-0 translate-middle-y"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbarFilter"
            >
              <span>
                <i className="bi bi-person me-2" />
                Account
              </span>
            </div>
            <div className="row">
              <div className="col-12 col-xl-3 filter-column">
                <AccountSidebar active="ORDERS" />
              </div>
              <div className="col-12 col-xl-9">
                <div className="card rounded-0 mb-3 bg-light">
                  <div className="card-body">
                    <div className="d-flex flex-column flex-xl-row gap-3 align-items-center">
                      <div className>
                        <h5 className="mb-1 fw-bold">All Orders</h5>
                      </div>
                    </div>
                  </div>
                </div>
                {orders &&
                  orders.map((order) => {
                    return <OrderCard order={order} />;
                  })}
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
      </div>
      <Footer />
      <div className="modal" id="FilterOrders" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h5 className="modal-title fw-bold">Filter Orders</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h6 className="mb-3 fw-bold">Status</h6>
              <div className="status-radio d-flex flex-column gap-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    defaultChecked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    All
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    On the way
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault3"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault3"
                  >
                    Delivered
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault4"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault4"
                  >
                    Cancelled
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault5"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault5"
                  >
                    Returned
                  </label>
                </div>
              </div>
              <hr />
              <h6 className="mb-3 fw-bold">Time</h6>
              <div className="status-radio d-flex flex-column gap-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioTime"
                    id="flexRadioDefault6"
                    defaultChecked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault6"
                  >
                    Anytime
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioTime"
                    id="flexRadioDefault7"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault7"
                  >
                    Last 30 days
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioTime"
                    id="flexRadioDefault8"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault8"
                  >
                    Last 6 months
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioTime"
                    id="flexRadioDefault9"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault9"
                  >
                    Last year
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="d-flex align-items-center gap-3 w-100">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-ecomm w-50"
                >
                  Clear Filters
                </button>
                <button type="button" className="btn btn-dark btn-ecomm w-50">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountOrders;
