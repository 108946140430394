import React, { useState, useEffect } from "react";
import Rating from "react-rating";

function ReviewSection({ product }) {
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    if (product && product.reviews) {
      const mapData = product.reviews.map((item) => Number(item.ratings));
      const averageRatingData =
        mapData.reduce((a, b) => a + b, 0) / product.reviews.length;
      setAverageRating(averageRatingData);
    }
  }, [product]);
  return (
    <div className="ratings mb-1 h6">
      <Rating
        initialRating={averageRating}
        emptySymbol="fa fa-star-o empty mr-3 "
        fullSymbol="fa fa-star full mr-3  "
        readonly
        fractions={2}
      />
      (
      {product.reviews && product.reviews.length > 0
        ? `${product.reviews.length} Reviews`
        : 0}
      )
    </div>
  );
}

export default ReviewSection;
