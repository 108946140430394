import React, { useState } from "react";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import { Formik, Form } from "formik";
import { SelectBox, TextArea, TextInput } from "../../components/Form/Form";
import * as Yup from "yup";
import { useGetCart } from "../../shared/hooks/UseCart";
import { useCreateOrder } from "../../shared/hooks/UseOrder";
import CheckoutFormUser from "./CheckoutFormUser";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import { useProfileAuth } from "../../shared/hooks/UseAuth";
import { useHistory } from "react-router-dom";

function Checkout() {
  const [cart, total] = useGetCart();
  const [user, isAuthenticated, loading] = useProfileAuth();
  const history = useHistory();
  const [data, addOrderData] = useCreateOrder();
  const [processingOrder, setProcessingOrder] = useState(false);
  const submitOrder = async (values) => {
    setProcessingOrder(true);
    console.log("Order Values", values);
    values.products = cart;
    values.sub_total = total;
    values.discount = 0;
    // TODO Discount
    values.delivery_charges = 0;
    values.total_amount = total + values.delivery_charges - values.discount;

    if (values.payment_method == "COD") {
      values.status = "PROCESSING";
      values.is_paid = false;
      await addOrderData(values);
      setProcessingOrder(false);
      localStorage.removeItem(process.env.REACT_APP_CART);
      history.push(`/thank-you`);
    }
    if (values.payment_method == "ONLINE") {
      values.status = "PROCESSING";
      values.is_paid = false;
      const order = await axios.get(
        `https://1stfarmer.com/razorpay/pay.php?amount=${values.total_amount}`
      );
      var options = {
        key: "rzp_live_P9e49Zrtn2axDE", // Enter the Key ID generated from the Dashboard
        amount: values.total_amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "1st Farmer",
        description: "",
        image: "https://1stfarmer.com/assets/img/logo/Farmer.png",
        order_id: order.data, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          values.status = "PROCESSING";
          values.is_paid = true;
          await addOrderData(values);
          history.push(`/thank-you`);
        },
        prefill: {
          name: values.customer && values.customer.name,
          email: values.customer && values.customer.email,
          contact: values.customer && values.customer.phone,
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert("Payment Failed Please try again");
      });
      rzp1.open();
      setProcessingOrder(false);
    }
    console.log("Final Value", values);
  };

  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrum title={"Checkout"} />
      </div>
      <section className="section-padding">
        {!loading && user ? (
          <CheckoutFormUser
            initialValues={{
              customer: {
                name: user.name,
                phone: user.phone,
                email: user.email,
                customer: user._id,
              },
              address:
                user.address && user.address.length > 0
                  ? user.address[0]
                  : {
                      address_1: "",
                      address_2: "",
                      city: "",
                      state: "",
                      pin: "",
                      landmark: "",
                    },
              payment_method: "ONLINE",
            }}
            user={user}
            submitOrder={submitOrder}
            processingOrder={processingOrder}
          />
        ) : (
          <CheckoutForm
            initialValues={{
              customer: {
                name: "",
                phone: "",
                email: "",
              },
              address: {
                address_1: "",
                address_2: "",
                city: "",
                state: "",
                pin: "",
                landmark: "",
              },
              payment_method: "ONLINE",
            }}
            user={null}
            submitOrder={submitOrder}
            processingOrder={processingOrder}
          />
        )}
      </section>

      <Footer />
    </div>
  );
}

export default Checkout;
