import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addToCartAction,
  getCart,
  decreaseQuantity,
  removeFromCartAction,
} from "../../store/actions/cart_action";

export const useGetCart = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.cart);
  const { cart } = data;
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (cart) {
      let totalAmount = 0;
      cart.map((item) => {
        totalAmount = totalAmount + item.sale_price * item.quantity;
      });
      setTotal(totalAmount);
    }
  }, [cart]);
  useEffect(() => {
    dispatch(getCart());
  }, []);
  return [cart, total];
};

export const useAddToCart = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cart);
  const { cart } = data;
  const addData = async (data) => {
    await dispatch(addToCartAction(data));
  };
  return [cart, addData];
};
export const useDecreaseQuantity = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cart);
  const { cart } = data;
  const addData = async (slug) => {
    await dispatch(decreaseQuantity(slug));
  };
  return [addData];
};

export const useRemoveFromCart = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cart);
  const { cart } = data;
  const addData = async (slug) => {
    await dispatch(removeFromCartAction(slug));
  };
  return [addData];
};
export const useCheckInCart = (product, activeVariantVersion) => {
  const [productInCart, setProductInCart] = useState(null);
  const data = useSelector((state) => state.cart);
  const { cart } = data;
  useEffect(() => {
    if (cart && product) {
      if (!product.is_variable_product) {
        const filterData = cart.filter((item) => item.slug == product.slug);
        if (filterData && filterData.length > 0) {
          setProductInCart(filterData[0]);
        } else {
          setProductInCart(null);
        }
      } else {
        if (activeVariantVersion) {
          const filterData = cart.filter(
            (item) =>
              item.slug ==
              `${product.slug}?variation=${activeVariantVersion._id}`
          );
          if (filterData && filterData.length > 0) {
            setProductInCart(filterData[0]);
          } else {
            setProductInCart(null);
          }
        }
      }
    }
  }, [cart, product, activeVariantVersion]);

  return [productInCart];
};
