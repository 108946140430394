import React from 'react'
import { Formik, Form } from 'formik'
import { TextInput } from '../Form/Form'
import * as Yup from 'yup'
import { useCreateNewsletter } from '../../shared/hooks/UseNewsletter'
function NewsLetter() {
  const [data, addData] = useCreateNewsletter()

  return (
    <div>
      <section className="product-thumb-slider subscribe-banner p-5">
        <div className="row">
          <div className="col-12 col-lg-6 mx-auto">
            <div className="text-center">
              <h3 className="mb-0 fw-bold text-white">
                Get Latest Update by <br /> Subscribe Our Newslater
              </h3>
              <div className="mt-3">
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    addData(values)
                    resetForm()

                    setSubmitting(false)
                  }}
                >
                  {(formik) => {
                    console.log(formik)
                    return (
                      <Form>
                        <TextInput
                          className="form-control form-control-lg bubscribe-control rounded-0 px-5 py-3"
                          name="email"
                          type="text"
                          placeholder="Enter Your Email"
                        />
                        <div className="mt-3 d-grid">
                          <button
                            type="submit"
                            className="btn btn-lg btn-ecomm bubscribe-button px-5 py-3"
                          >
                            Subscribe
                          </button>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NewsLetter
