import React, { useState } from "react";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import Filter from "../../components/filter/Filter";
import ProductCard from "../../components/product/ProductCard";
import { useAllProducts } from "../../shared/hooks/UseProduct";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../components/common/Pagination";
function Shop() {
  const [grid, setGrid] = useState(4);
  const [product_data] = useAllProducts();
  const { products, products_loading, total_products, pages } = product_data;
  return (
    <div>
      <Header />
      <Breadcrum />
      <section className="py-4">
        <h5 className="mb-0 fw-bold d-none">Product Grid</h5>
        <div className="container">
          <div
            className="btn btn-dark btn-ecomm d-xl-none position-fixed top-50 start-0 translate-middle-y"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbarFilter"
          >
            <span>
              <i className="bi bi-funnel me-1" /> Filters
            </span>
          </div>
          <div className="row">
            <div className="col-12 col-xl-3 filter-column">
              <Filter />
            </div>
            <div className="col-12 col-xl-9">
              <div className="shop-right-sidebar">
                <div className="card rounded-0">
                  <div className="card-body p-2">
                    <div className="d-flex align-items-center justify-content-between bg-light p-2">
                      <div className="product-count">
                        {total_products} Items Found
                      </div>
                      <div className="view-type hstack gap-2 d-none d-xl-flex">
                        <p className="mb-0">Grid</p>
                        <div>
                          <a
                            onClick={() => {
                              setGrid(3);
                            }}
                            className={
                              grid == 3
                                ? "grid-type-3 d-flex gap-1 active"
                                : "grid-type-3 d-flex gap-1 "
                            }
                          >
                            <span />
                            <span />
                            <span />
                          </a>
                        </div>
                        <div>
                          <a
                            onClick={() => {
                              setGrid(4);
                            }}
                            className={
                              grid == 4
                                ? "grid-type-3 d-flex gap-1 active"
                                : "grid-type-3 d-flex gap-1 "
                            }
                          >
                            <span />
                            <span />
                            <span />
                            <span />
                          </a>
                        </div>
                      </div>
                      {/* <form>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent rounded-0 border-0">
                            Sort By
                          </span>
                          <select className="form-select rounded-0">
                            <option selected>Whats'New</option>
                            <option value={1}>Popularity</option>
                            <option value={2}>Better Discount</option>
                            <option value={3}>Price : Hight to Low</option>
                            <option value={4}>Price : Low to Hight</option>
                            <option value={5}>Custom Rating</option>
                          </select>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </div>
                <div className="product-grid mt-4">
                  <div
                    className={
                      grid == 4
                        ? "row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4"
                        : "row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
                    }
                  >
                    {products &&
                      products.map((product) => {
                        return <ProductCard product={product} />;
                      })}
                    {products_loading && (
                      <>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                        <div className="card">
                          <img src="/assets/images/default-image.png" />
                          <div className="card-body">
                            <Skeleton height={20}> </Skeleton>
                            <Skeleton height={30}> </Skeleton>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/*end row*/}
                </div>
                <div
                  style={{
                    border: "1px solid #f1f1f1",
                    marginTop: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <Pagination
                    pages={pages}
                    count={total_products}
                    loading={products_loading}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Shop;
