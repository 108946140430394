import React from "react";
import { Link } from "react-router-dom";

import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";

function ThankYou({ }) {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrum title={"Thank You"} />
        <section className="section-padding">
          <div className="container">
            <div className="row border-gold g-4">

              <div className="col-md-7">
                <div className="thyankyou-img">
                  <img src="/assets/images/thankyou-order-3.png" />
                </div>
              </div>
              <div className="col-md-5">
                <div className="thankyou-content">
                  <h2 className="fw-bold">Thank You!</h2>
                  <hr />
                  <h3>Hurray! You’ve Successfully Done It!</h3>
                  <strong>
                    <h4>Thank you for ordering from us!</h4>
                  </strong>
                  <p>
                    You’ll receive your first email within the next 24 hours. We
                    hope our product will meet your expectations. Let us know if
                    you have any questions.
                  </p>

                  <div className="contact-us mt-5 mb-5 text-center thankyou-order-btn">
                    <Link className="theme-btn-sm-2 " to="/my-account">
                      View Order
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default ThankYou;
