import moment from "moment";
import React from "react";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import { URI } from "../../domain/constant";
import { useAllBlogs } from "../../shared/hooks/UseBlog";

function Blog() {
  const [data] = useAllBlogs();
  const { blogs, blogs_loading } = data;
  return (
    <div>
      <section className="section-padding">
        <div className="container">
          <div className="text-center pb-3">
            <h3 className="mb-0 fw-bold">Latest Blog</h3>
            <p className="mb-0 text-capitalize">Check our latest news</p>
          </div>
          <div className="blog-cards">
            <div className="row row-cols-1 row-cols-lg-3 g-4">
              {blogs &&
                blogs.map((item) => {
                  return (
                    <div className="col">
                      <div className="card">
                        <Link to={`/blogs/${item.slug}`}>
                          <img
                            src={`${URI}${item.image}`}
                            className="card-img-top rounded-0"
                          />
                        </Link>
                        <div className="card-body">
                          <div className="d-flex align-items-center gap-4">
                            <div className="posted-by">
                              <p className="mb-0">
                                <i className="bi bi-person me-2" />
                                {item.category && item.category.name}
                              </p>
                            </div>
                            <div className="posted-date">
                              <p className="mb-0">
                                <i className="bi bi-calendar me-2" />
                                {item.createdAt &&
                                  moment(item.createdAt).format("MMM DD, YYYY")}
                              </p>
                            </div>
                          </div>
                          <h5 className="card-title fw-bold mt-3">
                            {item.title}
                          </h5>
                          <div className="blog-card-content">
                            {item.description && renderHTML(item.description)}
                          </div>

                          <Link
                            to={`/blogs/${item.slug}`}
                            className="btn btn-outline-dark btn-ecomm mt-3"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {/*end row*/}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blog;
