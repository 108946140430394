import React, { useEffect } from 'react';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Home from './containers/home/Home';
import { loadUser } from './store/actions/auth';
import setAuthToken from './domain/setAuthToken';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import PageNotFound from './containers/notfound/PageNotFound';
import About from './containers/aboutus/About';
import Disclaimer from './containers/policy/Disclaimer';
import Privacy from './containers/policy/Privacy';
import Refund from './containers/policy/Refund';
import Terms from './containers/policy/Terms';
import Shop from './containers/shop/Shop';
import Contact from './containers/contact/Contact';
import SingleProduct from './containers/product/SingleProduct';
import Checkout from './containers/checkout/Checkout';
import PaymentOption from './containers/payment/PaymentOption';
import Cart from './containers/cart/Cart';
import MyAccount from './containers/myaccount/MyAccount';
import AccountOrders from './containers/myaccount/AccountOrders';
import Accountprofile from './containers/myaccount/Accountprofile';
import EditProfile from './containers/myaccount/EditProfile';
import SavedAddress from './containers/myaccount/SavedAddress';
import Wishlist from './containers/myaccount/Wishlist';
import VendorStore from './containers/vendor/VendorStore';
import SingleCollection from './containers/collections/SingleCollection';
import SignUp from './containers/authentication/SignUp';
import SignIn from './containers/authentication/SignIn';
import ForgetPassword from './containers/authentication/ForgetPassword';
import ResetPassword from './containers/authentication/ResetPassword';
import ThankYou from './containers/thank-you/ThankYou';
import VendorInformation from './containers/vendor/VendorInformation';
import VendorSignup from './containers/vendor/VendorSignup';
import PaymentPolicy from './containers/policy/PaymentPolicy';
import Faqs from './containers/policy/Faqs';
import ShippingDetails from './containers/policy/ShippingDetails';
import ReturnPolicy from './containers/policy/ReturnPolicy';
import BecomeSeller from './containers/policy/BecomeSeller';

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about-us' component={About} />
          <BeforeLoginRoutes exact path='/sign-up' component={SignUp} />
          <BeforeLoginRoutes
            exact
            path='/forget-password'
            component={ForgetPassword}
          />
          <Route
            exact
            path='/reset-password/:token'
            component={ResetPassword}
          />
          <BeforeLoginRoutes exact path='/login' component={SignIn} />
          <Route exact path='/contact-us' component={Contact} />
          <Route exact path='/thank-you' component={ThankYou} />
          <Route exact path='/shop' component={Shop} />
          <Route exact path='/collections/:slug' component={SingleCollection} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/cart' component={Cart} />
          <PrivateRoutes exact path='/edit-profile' component={EditProfile} />
          <PrivateRoutes exact path='/our-orders' component={AccountOrders} />
          <Route exact path='/our-wishlist' component={Wishlist} />
          <PrivateRoutes exact path='/our-profile' component={Accountprofile} />
          <PrivateRoutes exact path='/saved-address' component={SavedAddress} />
          <PrivateRoutes exact path='/my-account' component={MyAccount} />
          <Route exact path='/payment-option' component={PaymentOption} />
          <Route exact path='/product/:slug' component={SingleProduct} />
          <Route exact path='/disclaimer' component={Disclaimer} />
          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/refund-policy' component={Refund} />
          <Route exact path='/terms-and-conditions' component={Terms} />
          <Route exact path='/payments-policy' component={PaymentPolicy} />
          <Route exact path='/faqs' component={Faqs} />
          <Route exact path='/shipping-details' component={ShippingDetails} />
          <Route exact path='/return-policy' component={ReturnPolicy} />
          <Route exact path='/become-seller' component={BecomeSeller} />
          {/* vendor panel related routes */}
          <Route exact path='/vendor-store/:slug' component={VendorStore} />
          <Route exact path='/vendor-information' component={VendorInformation} />
          <Route exact path='/vendor-signup' component={VendorSignup} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
