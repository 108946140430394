import React, { useEffect, useState } from "react";
import Cart from "../../cart/Cart";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import { useGetCart, useRemoveFromCart } from "../../../shared/hooks/UseCart";
import { useHistory, useLocation } from "react-router";
import {
  useGetWishlist,
  useAddToWishlist,
} from "../../../shared/hooks/UseWishlist";
import * as qs from "qs";
import { URI } from "../../../domain/constant";
import { useSelectAllMenu } from "../../../shared/hooks/UseMenu";
import Wishlist from "../../wishlist/Wishlist";

function Header() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  const history = useHistory();
  const location = useLocation();
  const [addcartopen, setAddcartopen] = useState(false);
  const [menu_data] = useSelectAllMenu();

  const [wishlist, setWishlist] = useState(false);
  const [openmenu, setOpenmenu] = useState(false);
  const [cart, total] = useGetCart();
  const [removeFromCart] = useRemoveFromCart();
  const [wishlist_data] = useGetWishlist();
  const [wishlistData, removeWishlistData] = useAddToWishlist();
  // console.log("CART", wishlist_data);
  const [searchTerm, setSearchTerm] = useState("");
  const doSearch = () => {
    const queryParams = qs.parse(window.location.search.replace("?", ""));
    queryParams["search"] = { name: searchTerm };
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });

    history.push(`/shop?${query}`);
  };
  const { all_menus, all_menus_loading } = menu_data;
  const [activeMenu, setActiveMenu] = useState(null);
  // console.log(
  //   "all_menus",
  //   all_menus &&
  //     all_menus[0] &&
  //     all_menus[0].fields &&
  //     JSON.parse(all_menus[0].fields)
  // );
  useEffect(() => {
    if (all_menus && all_menus[0] && all_menus[0].fields) {
      setActiveMenu(JSON.parse(all_menus[0].fields));
    }
  }, [all_menus]);
  return (
    <>
      <header className="top-header">
        <nav className="navbar navbar-expand-xl w-100 navbar-dark container gap-3">
          <Link className="navbar-brand d-none d-xl-inline" to="/">
            <img src="/assets/images/logo.png" className="logo-img" alt />
          </Link>
          <a
            className="mobile-menu-btn d-inline d-xl-none"
            href="javascript:;"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
          >
            <i className="bi bi-list" />
          </a>
          <div
            className="offcanvas offcanvas-start"
            tabIndex={-1}
            id="offcanvasNavbar"
          >
            <div className="offcanvas-header">
              <div className="offcanvas-logo">
                <img src="/assets/images/logo.png" className="logo-img" alt />
              </div>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body primary-menu">
              <div className="">
                <ul className="navbar-nav justify-content-start flex-grow-1 gap-1">
                  {activeMenu &&
                    activeMenu.map((menu) => {
                      return (
                        <>
                          <li className="nav-item dropdown">
                            {
                              //   <a
                              //   className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                              //   href="#"
                              //   data-bs-toggle="dropdown"
                              // >
                              //   {menu.title}
                              // </a>
                            }
                            {menu.data && menu.data.link ? (
                              <Link
                                className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                                to={`/${menu.data.link}`}
                              >
                                {" "}
                                {menu.data && menu.data.title}
                              </Link>
                            ) : (
                              <a
                                href="#"
                                className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                              >
                                {menu.data && menu.data.title}
                              </a>
                            )}
                            {menu.data &&
                              menu.data.menu &&
                              menu.data.menu.length > 0 && (
                                <div className="dropdown-menu dropdown-large-menu">
                                  <div className="row">
                                    {menu.data.menu.map((sub_menu) => {
                                      return (
                                        <div className="col-12 col-xl-3">
                                          <h6 className="large-menu-title">
                                            {sub_menu.data &&
                                            sub_menu.data.link ? (
                                              <Link to={sub_menu.data.link}>
                                                {sub_menu.data &&
                                                  sub_menu.data.title}
                                              </Link>
                                            ) : (
                                              sub_menu.data &&
                                              sub_menu.data.title
                                            )}
                                          </h6>
                                          {sub_menu.data &&
                                            sub_menu.data.menu &&
                                            sub_menu.data.menu.length > 0 && (
                                              <ul className="list-unstyled">
                                                {sub_menu.data.menu.map(
                                                  (sub_sub_menu) => {
                                                    return (
                                                      <li>
                                                        <Link
                                                          to={
                                                            sub_sub_menu.data
                                                              .link
                                                          }
                                                        >
                                                          {
                                                            sub_sub_menu.data
                                                              .title
                                                          }
                                                        </Link>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                  {/* end row */}
                                </div>
                              )}
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="only-desktop">
            <div className="search">
              <div className="form-group has-search">
                <span className="bi bi-search form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for products, brands and more"
                />
              </div>
            </div>
          </div>
          <ul className="navbar-nav secondary-menu flex-row">
            <li className="nav-item only-mobile">
              <a className="nav-link" href="#">
                <i className="bi bi-search" />
              </a>
            </li>

            <li className="nav-item">
              <Link to={`/our-wishlist`} className="nav-link position-relative">
                <div className="cart-badge">
                  {wishlist_data && wishlist_data.length}
                </div>
                <i className="bi bi-suit-heart" />
              </Link>
            </li>
            <li
              className="nav-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
            >
              <a className="nav-link position-relative">
                <div className="cart-badge">{cart && cart.length}</div>
                <i className="bi bi-basket2" />
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/my-account">
                <i className="bi bi-person-circle" />
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      <Cart cart={cart} total={total} />
    </>
  );
}

export default Header;
