import React from "react";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import { Formik, Form } from "formik";
import { TextArea, TextInput } from "../../components/Form/Form";
import * as Yup from "yup";
import { useCreateContact } from "../../shared/hooks/UseContact";
function Contact() {
  const [data, addData] = useCreateContact();
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrum title={"Contact Us"} />
        <section className="section-padding">
          <div className="container">
            <div className="separator mb-3">
              <div className="line" />
              <h3 className="mb-0 h3 fw-bold">Find Us Map</h3>
              <div className="line" />
            </div>
            <div className="border p-3">
              <iframe
                className="w-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805184.6320105711!2d144.49269039866502!3d-37.971237001538135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sin!4v1654250375825!5m2!1sen!2sin"
                height={450}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className="separator my-3">
              <div className="line" />
              <h3 className="mb-0 h3 fw-bold">Why Choose Us</h3>
              <div className="line" />
            </div>
            <div className="row g-4">
              <div className="col-xl-8">
                <div className="p-4 border">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      message: "",
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Required"),
                      email: Yup.string().required("Required"),
                      phone: Yup.string().required("Required"),
                      message: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      addData(values);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <div className="form-body">
                            <h4 className="mb-0 fw-bold">Drop Us a Line</h4>
                            <div className="my-3 border-bottom" />
                            <div className="mb-3">
                              <TextInput
                                label="Enter Your Name"
                                name="name"
                                type="text"
                              />
                            </div>
                            <div className="mb-3">
                              <TextInput
                                label="Enter Email"
                                name="email"
                                type="text"
                              />
                            </div>
                            <div className="mb-3">
                              <TextInput
                                label="Phone Number"
                                name="phone"
                                type="number"
                              />
                            </div>
                            <div className="mb-3">
                              <TextArea
                                label="Message"
                                name="message"
                                rows={4}
                                cols={4}
                                type="text"
                              />
                            </div>

                            <div className="mb-0">
                              <button
                                type="submit"
                                className="btn btn-dark btn-ecomm"
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="p-3 border">
                  <div className="address mb-3">
                    <h5 className="mb-0 fw-bold">Address</h5>
                    <p className="mb-0 font-12">
                      123 Street Name, City, Australia
                    </p>
                  </div>
                  <hr />
                  <div className="phone mb-3">
                    <h5 className="mb-0 fw-bold">Phone</h5>
                    <p className="mb-0 font-13">Toll Free (123) 472-796</p>
                    <p className="mb-0 font-13">Mobile : +91-9910XXXX</p>
                  </div>
                  <hr />
                  <div className="email mb-3">
                    <h5 className="mb-0 fw-bold">Email</h5>
                    <p className="mb-0 font-13">mail@example.com</p>
                  </div>
                  <hr />
                  <div className="working-days mb-0">
                    <h5 className="mb-0 fw-bold">Working Days</h5>
                    <p className="mb-0 font-13">
                      Mon - FRI / 9:30 AM - 6:30 PM
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <p><strong>BL Center: Get All Shopping Solutions Here</strong></p>
                  <p><strong>&nbsp;</strong></p>

                  <p><strong>24x7 Customer Care Support</strong></p>
                  <p><strong>&nbsp;</strong></p>
                  <p>You can have 24x7 Customer Care Support on the BL Centre. Any query or issue that you may possibly have while shopping on Brown Laces is taken care here. This page is easy to navigate, and you can get support almost in one go. Once you log onto your Brown Laces account, this page shows you your recent orders and let you report any issue. By clicking on the specific order, you can raise your query. It also has a chat option to ensure that your queries and issues are taken care of. Also, there are other options on this page that are created to assist you and to make your shopping experience better than anywhere else.</p>

                  <p><strong>Topics of Support at Brown Laces</strong></p>
                  <p><strong>&nbsp;</strong></p>
                  <p>Apart from helping you with your orders and/or your delivered product-related issues, you can find various other support at BL Help Centre. You can select from three types of issues here - help with your issues, help with your order, and help with other issues. You can track your orders here, manage your orders, get help with your returns and refunds issues, and even get help related to various other issues, such as offers, payment, etc. There are also details about specific help topics, such as cancellations and returns, wallet, Brown Laces, etc. available here. So, log on to your Brown Laces account and shop without hassles and with complete help and support.&nbsp;</p>

                  <p>The BL Centre is available on the Brown Laces site to help every Brown Laces customer with any grievance that they may have. You can find solutions regarding the tracking of your order. It will also help edit your delivery date or address and more. All your issues will be addressed at any time of the day or night (24/7 service). This way, you can immediately get your grievances addressed. You can also get help regarding returns and refunds and many other issues through the BL Help Centre. In case you're not satisfied with the solution given, you can seek further assistance. You can get in touch with a support assistant via the Brown Laces Help Centre number. The next time you have any issue with your order or if you want more clarity regarding payment options, account-related queries, and more, you can access Brown Laces Help Centre support for further information. This way, you can shop without worrying and have a satisfying shopping experience. The support centre will do all that it can to address your grievance until you&rsquo;re completely satisfied. Compromise in any way.</p>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
