import React from 'react'
import { useSelectAllTestimonial } from '../../shared/hooks/UseTestimonial'

function Testimonial() {
  const [data] = useSelectAllTestimonial()
  const { all_testimonials, all_testimonials_loading } = data

  return (
    <section className="section-padding bg-section-2 testimonial">
      <div className="container">
        <div className=" border-0 rounded-0 p-3 ">
          <div className="row align-items-center justify-content-center">
            {all_testimonials &&
              all_testimonials.map((item) => {
                return (
                  <div className="col-md-4">
                    <div className="item">
                      <div className="testi-wrap">
                        <div className="quotes-icon">
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>

                        <div className="testi-caption">
                          <p>
                            <i>{item.testimonial}</i>
                          </p>
                          <div className="testi-flex">
                            <div>
                              <a href="#">
                                <i className="fa fa-user clr-txt" />
                                <strong> {item.name}</strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
