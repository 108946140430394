import React from "react";
import Brand from "../../components/brand/Brand";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";

function Terms({ title }) {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrum title={"Terms & Conditions"} />
        <section className='policy-page'>
          <div className="container policy-page-content">
            <div className="policy-page-heading">
              Terms & Conditions
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div>
                  {
                    // <p><strong><u>Terms of Use</u></strong></p>
                  }

                  <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                  <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name&nbsp;www.Brown Laces.com &nbsp;(&ldquo;Website&rdquo;), including the related mobile site and mobile application (hereinafter referred to as &ldquo;Platform&rdquo;)</p>
                  <p>The Platform is owned by Brown Laces Private Limited a company incorporated under the Companies Act, 1956 with its registered office at Delhi, India (hereinafter referred to as "Brown Laces").</p>

                  <p>Your use of the Platform and services and tools are governed by the following terms and conditions&nbsp;("Terms of Use")&nbsp;as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If you transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with Brown Laces Internet Private Limited and these terms and conditions including the policies constitute your binding obligations, with Brown Laces.</p>
                  <p>For the purpose of these Terms of Use, wherever the context so requires&nbsp;"You"&nbsp;or&nbsp;"User"&nbsp;shall mean any natural or legal person who has agreed to become a buyer on the Platform by providing Registration Data while registering on the Platform as Registered User using the computer systems. Brown Laces allows the User to surf the Platform or making purchases without registering on the Platform. The term&nbsp;"We", "Us", "Our"&nbsp;shall mean Brown Laces Internet Private Limited.</p>
                  <p>When You use any of the services provided by Us through the Platform, including but not limited to, (e.g. Product Reviews, Seller Reviews), You will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the Platform following the posting of changes will mean that you accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform.</p>
                  <p><strong><em><u>ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING</u></em></strong><strong><em><u>. </u></em></strong>By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Brown Laces Policies ((including but not limited to Privacy Policy available at Privacy) as amended from time to time.</p>
                  <p><strong>Membership Eligibility</strong></p>
                  <p>Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e. under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian.</p>
                  <p>As a minor if you wish to transact on the Platform, such transaction on the Platform may be made by your legal guardian or parents. Brown Laces reserves the right to terminate your membership and / or refuse to provide you with access to the Platform if it is brought to Brown Laces's notice or if it is discovered that you are under the age of 18 years and transacting on the Platform.</p>
                  <p><strong>Your Account and Registration Obligations</strong></p>
                  <p>If You use the Platform, You shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide You with access to the Platform.</p>
                  <p>Your mobile phone number and/or e-mail address is treated as your primary identifier on the Platform. It is your responsibility to ensure that your mobile phone number and your email address is up to date on the Platform at all times. You agree to notify us promptly if your mobile phone number or e-mail address changes by updating the same on the Platform through one-time password verification.</p>
                  <p>You agree that Brown Laces shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your Account in cases, including, where you have failed to update your revised mobile phone number and/or e-mail address on the Website Platform.</p>
                  <p>If You share or allow others to have access to Your account on the Platform (&ldquo;Account&rdquo;), by creating separate profiles under Your Account, or otherwise, they will be able to view and access Your Account information. You shall be solely liable and responsible for all the activities undertaken under Your Account, and any consequences there from.</p>
                  <p><strong>&nbsp;</strong></p>
                  <p><strong>Communications</strong></p>
                  <p>When You use the Platform or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email or by such other mode of communication, electronic or otherwise.</p>
                  <p>Platform for Transaction and Communication</p>
                  <p>The Platform is a platform that Users utilize to meet and interact with one another for their transactions. Brown Laces is not and cannot be a party to or control in any manner any transaction between the Platform's Users.</p>
                  <ol>
                    <li>All commercial/contractual terms are offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. Brown Laces does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Sellers. All discounts, offers (including exchange offers) are by the Seller/Brand and not by Brown Laces.</li>
                    <li>Placement of order by a Buyer with Seller on the Platform is an offer to buy the product(s) in the order by the Buyer to the Seller and it shall not be construed as Seller's acceptance of Buyer's offer to buy the product(s) ordered. The Seller retains the right to cancel any such order placed by the Buyer, at its sole discretion and the Buyer shall be intimated of the same by way of an email/SMS. Any transaction price paid by Buyer in case of such cancellation by Seller shall be refunded to the Buyer. Further, the Seller may cancel an order wherein the quantities exceed the typical individual consumption. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity that exceeds the typical individual consumption. What comprises a typical individual's consumption quantity limit shall be based on various factors and at the sole discretion of the Seller and may vary from individual to individual.</li>
                    <li>Brown Laces does not make any representation or Warranty as to specifics (such as quality, value, salability, etc) of the products or services proposed to be sold or offered to be sold or purchased on the Platform. Brown Laces does not implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. Brown Laces accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</li>
                    <li>Brown Laces is not responsible for any non-performance or breach of any contract entered into between Buyers and Sellers. Brown Laces cannot and does not guarantee that the concerned Buyers and/or Sellers will perform any transaction concluded on the Platform. Brown Laces shall not and is not required to mediate or resolve any dispute or disagreement between Buyers and Sellers.</li>
                    <li>Brown Laces does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. You are advised to independently verify the bona fides of any particular User that You choose to deal with on the Platform and use Your best judgment in that behalf.</li>
                    <li>Brown Laces does not at any point of time during any transaction between Buyer and Seller on the Platform come into or take possession of any of the products or services offered by Seller nor does it at any point gain title to or have any rights or claims over the products or services offered by Seller to Buyer.</li>
                    <li>At no time shall Brown Laces hold any right, title or interest over the products nor shall Brown Laces have any obligations or liabilities in respect of such contract entered into between Buyers and Sellers. Brown Laces is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</li>
                    <li>The Platform is only a platform that can be utilized by Users to reach a larger base to buy and sell products or services. Brown Laces is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Seller and the Buyer.</li>
                  </ol>
                  <p>At no time shall Brown Laces hold any right, title or interest over the products nor shall Brown Laces have any obligations or liabilities in respect of such contract.</p>
                  <p>Brown Laces is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p>
                  <ol>
                    <li>You shall independently agree upon the manner and terms and conditions of delivery, payment, insurance etc. with the seller(s) that you transact with.</li>
                  </ol>
                  <p><strong><u>Disclaimer:</u></strong> Pricing on any product(s) as is reflected on the Platform may due to some technical issue, typographical error or product information published by seller may be incorrectly reflected and in such an event seller may cancel such your order(s).</p>
                  <ol>
                    <li>You release and indemnify Brown Laces and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that you may have in this behalf under any applicable law. Notwithstanding its reasonable efforts in that behalf, Brown Laces cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Platform.</li>
                  </ol>
                  <p>Please note that there could be risks in dealing with underage persons or people acting under false pretence.</p>
                  <p>Charges</p>
                  <p>Brown Laces may charge a nominal fee for browsing and buying on the Platform. Brown Laces reserves the right to change its Fee Policy from time to time. In particular, Brown Laces may at its sole discretion introduce new services/fees and modify some or all of the existing services/fees offered on the Platform. In such an event, Brown Laces reserves the right to introduce fees for the new services offered or amend/introduce fees for existing/new services, as the case may be. Changes to the Fee Policy shall be posted on the Platform and such changes shall automatically become effective immediately after they are posted on the Platform. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for compliance of all applicable laws including those in India for making payments to Brown Laces Internet Private Limited</p>
                  <p>Use of the Platform</p>
                  <p>You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding principles:</p>
                  <ol>
                    <li>You shall not host, display, upload, modify, publish, transmit, update or share any information which:</li>
                  </ol>
                  <p>(a) belongs to another person and to which You does not have any right to;</p>
                  <p>(b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p>
                  <p>(c) is misleading in any way;</p>
                  <p>(d) is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</p>
                  <p>(e) harasses or advocates harassment of another person;</p>
                  <p>(f) involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</p>
                  <p>(g) promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</p>
                  <p>(h) infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];</p>
                  <p>(i) promotes an illegal or unauthorized copy of another person's copyrighted work (see "Copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</p>
                  <p>(j) contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</p>
                  <p>(k) provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</p>
                  <p>(l) provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</p>
                  <p>(m) contains video, photographs, or images of another person (with a minor or an adult).</p>
                  <p>(n) tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</p>
                  <p>(o) engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" products related to the Platform. Throughout this Terms of Use, Brown Laces's prior written consent means a communication coming from Brown Laces's Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;</p>
                  <p>(p) solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</p>
                  <p>(q) interferes with another USER's use and enjoyment of the Platform or any other individual's User and enjoyment of similar services;</p>
                  <p>(r) refers to any Platform or URL that, in Our sole discretion, contains material that is inappropriate for the Platform or any other Platform, contains content that would be prohibited or violates the letter or spirit of these Terms of Use.</p>
                  <p>(s) harm minors in any way;</p>
                  <p>(t) infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</p>
                  <p>(u) violates any law for the time being in force;</p>
                  <p>(v) deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
                  <p>(w) impersonate another person;</p>
                  <p>(x) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</p>
                  <p>(y) threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</p>
                  <p>(z) shall not be false, inaccurate or misleading;</p>
                  <p>(aa) shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</p>
                  <p>(ab) shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers;</p>
                  <ol>
                    <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve our right to bar any such activity.</li>
                    <li>You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.</li>
                    <li>You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to Platform, or any other customer, including any account on the Platform not owned by You, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Platform.</li>
                    <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.</li>
                    <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to Us on or through the Platform or any service offered on or through the Platform. You may not pretend that you are, or that You represent, someone else, or impersonate any other individual or entity.</li>
                    <li>You may not use the Platform or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Brown Laces and / or others.</li>
                    <li>You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules there under as applicable and as amended from time to time and also all applicable Domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or Regulations in Force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and Regulations (including, but not limited to Sales Tax/VAT, Income Tax, Octori, Service Tax, Central Excise, Custom Duty, Local Levies) regarding Your use of Our service and Your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</li>
                    <li>Solely to enable Us to use the information You supply Us with, so that we are not violating any rights You might have in Your Information, You agree to grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your Information, in any media now known or not currently known, with respect to Your Information. We will only use your information in accordance with the Terms of Use and Privacy Policy applicable to use of the Platform.</li>
                    <li>From time to time, You shall be responsible for providing information relating to the products or services proposed to be sold by You. In this connection, You undertake that all such information shall be accurate in all respects. You shall not exaggerate or over emphasize the attributes of such products or services so as to mislead other Users in any manner.</li>
                    <li>You shall not engage in advertising to, or solicitation of, other Users of the Platform to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. In order to protect Our Users from such advertising or solicitation, We reserve the right to restrict the number of messages or emails which a user may send to other Users in any 24-hour period which We deems appropriate in its sole discretion. You understand that We have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as we, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>
                  </ol>
                  <p>We reserve the right, but have no obligation, to monitor the materials posted on the Platform. Brown Laces shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect Brown Laces views. In no event shall Brown Laces assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that You have all necessary rights in and to all Content which You provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.</p>
                  <p>Terms &amp; Conditions:</p>
                  <p>SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE SUITABLE FOR SOME VIEWERS AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED ON THE PLATFORM MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THE PLATFORM.</p>
                  <p><strong>Privacy</strong></p>
                  <p>We view protection of Your privacy as a very important principle. We understand clearly that You and Your Personal Information is one of Our most important assets. We store and process Your Information including any sensitive financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and Rules there under. Our current Privacy Policy is available at Privacy. Our current Privacy Policy is available at Privacy. f You object to Your Information being transferred or used in this way please do not use Platform. If You object to Your Information being transferred or used in this way please do not use Platform.</p>
                  <p>We may share personal information with our other corporate entities and affiliates. These entities and affiliatesmay market to you as a result of such sharing unless you explicitly opt-out.</p>
                  <p>We may disclose personal information to third parties. This disclosure may be required for us to provide you access to our Services, to comply with our legal obligations, to enforce our User Agreement, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.</p>
                  <p>We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>
                  <p>We and our affiliates will share / sell some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.</p>


                  <p><strong>Disclaimer of Warranties and Liability</strong></p>
                  <p>This Platform, all the materials and products (including but not limited to software) and services, included on or otherwise made available to You through this site are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, Brown Laces does not warrant that:</p>
                  <p>This Platform will be constantly available, or available at all; or</p>
                  <p>The information on this Platform is complete, true, accurate or non-misleading.</p>
                  <p>Brown Laces will not be liable to You in any way or in relation to the Contents of, or use of, or otherwise in connection with, the Platform. Brown Laces does not warrant that this site; information, Content, materials, product (including software) or services included on or otherwise made available to You through the Platform; their servers; or electronic communication sent from Us are free of viruses or other harmful components.</p>
                  <p>Nothing on Platform constitutes, or is meant to constitute, advice of any kind. All the Products sold on Platform are governed by different state laws and if Seller is unable to deliver such Products due to implications of different state laws, Seller will return or will give credit for the amount (if any) received in advance by Seller from the sale of such Product that could not be delivered to You.</p>
                  <p>You will be required to enter a valid phone number while placing an order on the Platform. By registering Your phone number with us, You consent to be contacted by Us via phone calls, SMS notifications, mobile applications and/or any other electronic mode of communication in case of any order or shipment or delivery related updates.</p>
                  <p>Selling</p>
                  <p>As a registered seller, you are allowed to list item(s) for sale on the Platform in accordance with the Policies which are incorporated by way of reference in this Terms of Use. You must be legally able to sell the item(s) you list for sale on our Platform. You must ensure that the listed items do not infringe upon the intellectual property, trade secret or other proprietary rights or rights of publicity or privacy rights of third parties. Listings may only include text descriptions, graphics and pictures that describe your item for sale. All listed items must be listed in an appropriate category on the Platform. All listed items must be kept in stock for successful fulfilment of sales.</p>
                  <p>The listing description of the item must not be misleading and must describe actual condition of the product. If the item description does not match the actual condition of the item, you agree to refund any amounts that you may have received from the Buyer. You agree not to list a single product in multiple quantities across various categories on the Platform. Brown Laces reserves the right to delete such multiple listings of the same product listed by you in various categories.</p>
                  <p>Services</p>
                  <p><strong>Payment</strong></p>
                  <p>While availing any of the payment method/s available on the Platform, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to You due to:</p>
                  <ol>
                    <li>Lack of authorization for any transaction/s, or</li>
                    <li>Exceeding the preset limit mutually agreed by You and between "Bank/s", or</li>
                    <li>Any payment issues arising out of the transaction, or</li>
                    <li>Decline of transaction for any other reason/s</li>
                  </ol>
                  <p>All payments made against the purchases/services on Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. Platform will not facilitate transaction with respect to any other form of currency with respect to the purchases made on Platform.</p>
                  <p>Before shipping / delivering your order to you, Seller may request you to provide supporting documents (including but not limited to Govt. issued ID and address proof) to establish the ownership of the payment instrument used by you for your purchase. This is done in the interest of providing a safe online shopping environment to Our Users.</p>
                  <p><strong>Further:</strong></p>
                  <ol>
                    <li>Transactions, Transaction Price and all commercial terms such as Delivery, Dispatch of products and/or services are as per principal to principal bipartite contractual obligations between Buyer and Seller and payment facility is merely used by the Buyer and Seller to facilitate the completion of the Transaction. Use of the payment facility shall not render Brown Laces liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after sales or warranty services or fraud as regards the products and /or services listed on Brown Laces's Platform.</li>
                    <li>You have specifically authorized Brown Laces or its service providers to collect, process, facilitate and remit payments and / or the Transaction Price electronically or through Cash on Delivery to and from other Users in respect of transactions through Payment Facility. Your relationship with Brown Laces is on a principal to principal basis and by accepting these Terms of Use you agree that Brown Laces is an independent contractor for all purposes, and does not have control of or liability for the products or services that are listed on Brown Laces's Platform that are paid for by using the Payment Facility. Brown Laces does not guarantee the identity of any User nor does it ensure that a Buyer or a Seller will complete a transaction.</li>
                    <li>You understand, accept and agree that the payment facility provided by Brown Laces is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment through Cash On Delivery, collection and remittance facility for the Transactions on the Brown Laces Platform using the existing authorized banking infrastructure and Credit Card payment gateway networks. Further, by providing Payment Facility, Brown Laces is neither acting as trustees nor acting in a fiduciary capacity with respect to the Transaction or the Transaction Price.</li>
                  </ol>
                  <p><strong>Payment Facility for Buyers:</strong></p>
                  <ol>
                    <li>You, as a Buyer, understand that upon initiating a Transaction You are entering into a legally binding and enforceable contract with the Seller to purchase the products and /or services from the Seller using the Payment Facility, and You shall pay the Transaction Price through Your Issuing Bank to the Seller using Payment Facility.</li>
                    <li>You, as a Buyer, may agree with the Seller through electronic communication and electronic records and using the automated features as may be provided by Payment Facility on any extension / increase in the Dispatch and/or Delivery time and the Transaction shall stand amended to such extent. Any such extension / increase of Dispatch / Delivery time or subsequent novation / variation of the Transaction should be in compliance with Payment Facility Rules and Policies.</li>
                    <li>You, as a Buyer, shall electronically notify Payment Facility using the appropriate Brown Laces Platform features immediately upon Delivery or non Delivery within the time period as provided in Policies. Non notification by You of Delivery or non Delivery within the time period specified in the Policies shall be construed as a deemed Delivery in respect of that Transaction. In case of Cash On Delivery transactions, Buyer is not required to confirm the receipt of products or services.</li>
                    <li>You, as a Buyer, shall be entitled to claim a refund of the Transaction Price (as Your sole and exclusive remedy) in case You do not receive the Delivery within the time period agreed in the Transaction or within the time period as provided in the Policies, whichever is earlier. In case you do not raise a refund claim using Platform features within the stipulated time than this would make You ineligible for a refund.</li>
                    <li>You, as a Buyer, understand that the Payment Facility may not be available in full or in part for certain category of products and/or services and/or Transactions as mentioned in the Policies and hence You may not be entitled to a refund in respect of the Transactions for those products and /or services</li>
                    <li>Except for Cash On Delivery transaction, refund, if any, shall be made at the same Issuing Bank from where Transaction Price was received, or through any other method available on the Platform, as chosen by You.</li>
                    <li>For Cash On Delivery transactions, refunds, if any, will be made via electronic payment transfers.</li>
                    <li>Refund shall be made in Indian Rupees only and shall be equivalent to the Transaction Price received in Indian Rupees.</li>
                    <li>Refunds may be supported for select banks. Where a bank is not supported for processing refunds, You will be required to share alternate bank account details with us for processing the refund.</li>
                    <li>Refund shall be conditional and shall be with recourse available to Brown Laces in case of any misuse by Buyer.</li>
                    <li>We may also request you for additional documents for verification.</li>
                    <li>Refund shall be subject to Buyer complying with Policies.</li>
                    <li>Brown Laces reserves the right to impose limits on the number of Transactions or Transaction Price which Brown Laces may receive from on an individual Valid Credit/Debit/ Cash Card / Valid Bank Account/ and such other infrastructure or any other financial instrument directly or indirectly through payment aggregator or through any such facility authorized by Reserve Bank of India to provide enabling support facility for collection and remittance of payment or by an individual Buyer during any time period, and reserves the right to refuse to process Transactions exceeding such limit.</li>
                    <li>Brown Laces reserves the right to refuse to process Transactions by Buyers with a prior history of questionable charges including without limitation breach of any agreements by Buyer with Brown Laces or breach/violation of any law or any charges imposed by Issuing Bank or breach of any policy.</li>
                    <li>Brown Laces may do such checks as it deems fit before approving the receipt of/Buyers commitment to pay (for Cash On Delivery transactions) Transaction Price from the Buyer for security or other reasons at the discretion of Brown Laces. As a result of such check if Brown Laces is not satisfied with the creditability of the Buyer or genuineness of the Transaction or other reasons at its sole discretion, Brown Laces shall have the right to reject the receipt of / Buyers commitment to pay Transaction Price. For avoidance of doubt, it is hereby clarified that the &lsquo;Cash on Delivery&rsquo; feature for payment, may be disabled for certain account users, at the sole discretion of Brown Laces.</li>
                    <li>Brown Laces may delay notifying the payment confirmation i.e. informing Seller to Dispatch, if Brown Laces deems suspicious or for Buyers conducting high transaction volumes to ensure safety of the Transaction and Transaction Price. In addition, Brown Laces may hold Transaction Price and Brown Laces may not inform Seller to Dispatch or remit Transaction Price to law enforcement officials (instead of refunding the same to Buyer) at the request of law enforcement officials or in the event the Buyer is engaged in any form of illegal activity.</li>
                    <li>The Buyer and Seller acknowledge that Brown Laces will not be liable for any damages, interests or claims etc. resulting from not processing a Transaction/Transaction Price or any delay in processing a Transaction/Transaction Price which is beyond control of Brown Laces.</li>
                  </ol>
                  <p><strong>Compliance with Laws:</strong></p>
                  <ol>
                    <li>As required by applicable law, if the Customer makes a purchase of an amount equal to or above INR 2 00 000.00, the Customer will be required to upload a scanned copy of his/her PAN card on the Platform, within 4 days of making the purchase, failing which, the purchase made by the Customer will be cancelled. The requirement to submit the PAN card arises only once and if it has been submitted once by the Customer, it need not be submitted again. The order of the Customer shall stand cancelled if there is a discrepancy between the name of the Customer and the name on the PAN Card.</li>
                    <li>Buyer and Seller shall comply with all the applicable laws (including without limitation Foreign Exchange Management Act, 1999 and the rules made and notifications issued there under and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act, 2000 as amended by the Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made there under, Foreign Contribution Regulation Act, 1976 and the rules made there under, Income Tax Act, 1961 and the rules made there under, Export Import Policy of government of India) applicable to them respectively for using Payment Facility and Brown Laces Platform.</li>
                  </ol>
                  <p><strong>Buyer's arrangement with Issuing Bank:</strong></p>
                  <ol>
                    <li>All Valid Credit / Debit/ Cash Card/ and other payment instruments are processed using a Credit Card payment gateway or appropriate payment system infrastructure and the same will also be governed by the terms and conditions agreed to between the Buyer and the respective Issuing Bank and payment instrument issuing company.</li>
                    <li>All Online Bank Transfers from Valid Bank Accounts are processed using the gateway provided by the respective Issuing Bank which support Payment Facility to provide these services to the Users. All such Online Bank Transfers on Payment Facility are also governed by the terms and conditions agreed to between Buyer and the respective Issuing Bank.</li>
                  </ol>
                  <p>Brown Laces's Replacement Guarantee*</p>
                  <p>Brown Laces's Replacement Guarantee seeks to assist Buyers who have been defrauded by qualified sellers on the Platform. The return policy period (Brown Laces's Replacement Guarantee) depends on the product category and the seller. Kindly click here to know the return policy period applicable for different categories. If at the time of delivery and/or within the applicable return policy period, if any defect is found, then the buyer of the product/s can ask for replacement of the product/s from the seller subject to the following terms and conditions</p>
                  <ol>
                    <li>Notify seller of any defects in the product/s at the time of delivery of the product/s and/or within the applicable return policy period and the same product/s will be replaced in return of the defective product/s.</li>
                    <li>Replacement can be for the entire product/s or part/s of the product subject to availability of the same with the seller.</li>
                  </ol>
                  <p><strong>Following products shall not be eligible for return or replacement:</strong></p>
                  <ol>
                    <li>Damages due to misuse of product;</li>
                    <li>Incidental damage due to malfunctioning of product;</li>
                    <li>Products with tampered or missing serial/UPC numbers;</li>
                    <li>Any damage/defect which are not covered under the manufacturer's warranty</li>
                    <li>Any product that is returned without all original packaging and accessories, including the box, manufacturer's packaging if any, and all other items originally included with the product/s delivered;</li>
                  </ol>
                  <p>Original tags and packaging should be intact. For items that come in branded packaging, the box should be undamaged.</p>
                  <p>If Brown Laces has any suspicion or knowledge that any of its buyers and sellers are involved in any activity that is intended to provide claims or information that is false or not genuine, Brown Laces may also, while reserving its rights to initiate civil and/or criminal proceedings against such member buyers and sellers, at its sole discretion, suspend, block, restrict, cancel the Display Name of such buyers and sellers and/or disqualify that user and any related users from availing protection through this program. Customers who have been blocked for any suspicious or fraudulent activity on Brown Laces will not be allowed to return their products.</p>
                  <p>Brown Laces reserves its right to initiate civil and/or criminal proceedings against a user who, files a invalid and/or false claims or provides false, incomplete, or misleading information. In addition to the legal proceedings as aforesaid, Brown Laces may at its sole discretion suspend, block, restrict, cancel the Display Name [and its related Display Names] of such user and/or disqualify that user and any related users from availing protection through this program. Any person who, knowingly and with intent to injure, defraud or deceive, files a Fraudulent Complaint containing false, incomplete, or misleading information may be guilty of a criminal offence and will be prosecuted to the fullest extent of the law.</p>
                  <p><strong>Exchange Offers:</strong></p>
                  <p>By participating in the exchange I confirm that I am the sole and absolute owner and/or user of the product mentioned above (device).</p>
                  <p>I confirm that device which I am exchanging under the buyback program is genuine and is not counterfeit, free from any and all encumbrances, liens, attachments, disputes, legal flaws, exchange or any Agreement of Sale etc. and I have got the clear ownership of the said device.</p>
                  <p>You agree to indemnity and keep indemnifying Brown Laces.com and any future buyer of the device against all or any third party claims, demand, cost, expenses including attorney fees which may be suffered, incurred, undergone and / or sustained by Brown Laces.com, its affiliates or any future buyer due to usage of the device by you till date and you undertake to make good the same.</p>
                  <p>I confirm that all the data in the said device will be erased before handing it over under buy back program. I also confirm that in spite of erasing the data manually/electronically, if any data still accessible due any technical reason, Brown Laces.com, Seller or the Manufacturer shall not be responsible for the same and I will not approach Brown Laces.com for any retrieval of the data.</p>
                  <p>I hereby give my consent that my personal information that I have provided in connection with this buyback program might be processed, transferred and retained by the retailer and other entities involved in managing the program for the purposes of validating the information that I provided herein and for the administration of the program.</p>
                  <p>I agree to indemnity and keep indemnifying the Brown Laces.com and any future buyer of the old device against all or any third party claims, demand, cost, expenses including attorney fees which may be suffered, incurred, undergone and / or sustained by Brown Laces.com, its affiliates or any future buyer due to usage of the device by me till date and I undertake to make good the same.</p>
                  <p>I understand once a device is sent by me to Brown Laces, in no scenario can this device be returned back to me.</p>
                  <p>I understand that the new device delivery and the old device pickup will happen simultaneously (hand in hand) and I shall keep the old device ready to be given for exchange.</p>
                  <p>Product Description</p>
                  <p>Brown Laces we do not warrant that Product description or other content of this Platform is accurate, complete, reliable, current, or error-free and assumes no liability in this regard.</p>

                  <p>***********</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
