import React from "react";
import {
  useAddToWishlist,
  useCheckInWishlist,
} from "../../shared/hooks/UseWishlist";
import {
  useAddToCart,
  useCheckInCart,
  useDecreaseQuantity,
  useRemoveFromCart,
} from "../../shared/hooks/UseCart";

function ActionButtonsCard({ product, activeVariantVersion }) {
  const [wishlist, addToWishlist] = useAddToWishlist();
  const [productInWishlist] = useCheckInWishlist(product);

  //   Add To Cart
  const [productInCart] = useCheckInCart(product, activeVariantVersion);
  const [cart, addData] = useAddToCart();
  const [removeFromCart] = useRemoveFromCart();

  const addToCart = async (product) => {
    if (!product.is_variable_product) {
      await addData({
        name: product.name,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: product.slug,
        image: product.media && product.media[0],
        product: product._id,
      });
    } else {
      let title = "";
      activeVariantVersion &&
        activeVariantVersion.options &&
        activeVariantVersion.options.map((item) => {
          title = title + " " + item.value;
        });
      await addData({
        name: `${product.name} ${title}`,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: `${product.slug}?variation=${activeVariantVersion._id}`,
        image: product.media && product.media[0],
        product: product._id,
      });
    }
  };
  const handleAddToWishlist = () => {
    if (!product.is_variable_product) {
      addToWishlist(product, activeVariantVersion)
    } else {
      if (activeVariantVersion) {
        addToWishlist(product, activeVariantVersion)
      }
    }

  }


  return (
    <div className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
      <a
        onClick={() => handleAddToWishlist()}
        className={productInWishlist ? "active-link" : "normal-link"}
      >
        <i className="bi bi-heart" />
      </a>
      {productInCart ? (
        <a
          onClick={() =>
            removeFromCart(
              product.is_variable_product && activeVariantVersion
                ? `${product.slug}?variation=${activeVariantVersion._id}`
                : product.slug
            )
          }
          className={productInCart ? "active-link" : "normal-link"}
        >
          {productInCart ? (
            <i class="bi bi-bag-check-fill"></i>
          ) : (
            <i className="bi bi-basket3" />
          )}
          {/* <i className="bi bi-basket3" /> */}
        </a>
      ) : (
        <a onClick={() => addToCart(product)}>
          <i className="bi bi-basket3" />

          {/* <i className="bi bi-basket3" /> */}
        </a>
      )}

      {/* <a>
        <i className="bi bi-zoom-in" />
      </a> */}
    </div>
  );
}

export default ActionButtonsCard;
