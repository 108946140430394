import React from 'react'
import Brand from "../../components/brand/Brand";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";

function BecomeSeller() {
    return (
        <div>
            <Header />
            <div className="page-content">
                <Breadcrum title={"Become Seller"} />
                <section className='policy-page'>
                    <div className="container policy-page-content">
                        <div className="policy-page-heading">
                            Become Seller
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <p><em>Brown Laces</em> Marketplace is India&rsquo;s leading platform for selling online footwears. You may be a manufacturer, vendor or supplier, simply sell your products online on Brown Laces and become a top e-commerce player with a ZERO investment. Through a team of experts offering exclusive seller workshops, training, and&nbsp;<a href="/">seller support</a>, Brown Laces focuses on empowering sellers dealing in footwear category across India.</p>
                                    <p>Selling on Brown Laces.com is an easy job on mutually agreed commission basis. All you need is to&nbsp;<a href="/">register</a>, upload your catalogue and start selling your products.</p>
                                    <p><strong><u>Shipping and Transportation</u></strong></p>
                                    <p>We have third party &lsquo;E-commerce Service Providers&rsquo; who provide logistics,&nbsp;<a href="/">cataloging support</a>, product photo shoot and packaging materials. We provide&nbsp;shipping advices through which you can ensure faster delivery of your items, quality check by our experts and a delightful packaging. Combine these with the&nbsp;<a href="/">fastest payments</a>&nbsp;in the industry and you get an excellent seller portal. Brown Laces is a growing India&rsquo;s leading and favourite place to&nbsp;<a href="/">sell online</a>.</p>
                                    <p><strong>&nbsp;</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default BecomeSeller