import React from 'react'
import Brand from "../../components/brand/Brand";
import Breadcrum from "../../components/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";

function ReturnPolicy() {
    return (
        <div>
            <Header />
            <div className="page-content">
                <Breadcrum title={"Return Policy"} />
                <section className='policy-page'>
                    <div className="container policy-page-content">
                        <div className="policy-page-heading">
                            Return Policy
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Returns is a scheme provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy on the product page for any exceptions to this returns policy and the table below&rdquo;</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The return policy is divided into three parts; Do read all sections carefully to understand the conditions and cases under which returns will be accepted.</p>
                                    <p>Part 1 &ndash; Category, Return Window and Actions possible</p>
                                    <p>&nbsp;</p>
                                    <table className='table table-bordered table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p><strong>Category</strong></p>
                                                </td>
                                                <td>
                                                    <p>Returns Window, Actions Possible and Conditions (if any)</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><strong>Home: Pet Supplies &amp; Rest of Home. (Except Home d&eacute;cor, Furnishing, Home Improvement Tools, Household Items)</strong></p>
                                                </td>
                                                <td>
                                                    <p>07 days</p>
                                                    <p>Refund or Replacement</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>Part 2 - Returns Pick-Up and Processing</p>
                                    <p>In case of returns where you would like item(s) to be picked up from a different address, the address can only be changed if pick-up service is available at the new address</p>
                                    <p>During pick-up, your product will be checked for the following conditions:</p>
                                    <table className='table table-bordered table-striped'>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <p><strong>Category</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Conditions</strong></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><strong>Correct Product</strong></p>
                                                </td>
                                                <td>
                                                    <p>IMEI/ name/ image/ brand/ serial number/ article number/ bar code should match and MRP tag should be undetached and clearly visible.</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><strong>Unused Product</strong></p>
                                                </td>
                                                <td>
                                                    <p>The product should be unused, unwashed, unsoiled, without any stains and with non-tampered quality check seals/return tags/warranty seals (wherever applicable). devices.</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><strong>Non-damaged Product</strong></p>
                                                </td>
                                                <td>
                                                    <p>The product should be undamaged and without any scratches, tears or holes.</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><strong>Undamaged Packaging</strong></p>
                                                </td>
                                                <td>
                                                    <p>The product&rsquo;s original packaging/ box should be undamaged.</p>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <p><strong><u>The field executive will refuse to accept the return if any of the above conditions are not met properly:</u></strong></p>
                                    <p>For any products for which a refund is to be given, the refund will be processed once the returned product has been received by the seller.</p>
                                    <p><strong>Part 3 - General Rules for a successful Return</strong></p>
                                    <ol>
                                        <li>In certain cases where the seller is unable to process a replacement for any reason whatsoever, a refund will be given.</li>
                                        <li>In cases where a product accessory is found missing/damaged/defective, the seller may either process a replacement of the particular accessory or issue an voucher for an amount equivalent to the price of the accessory, at the seller&rsquo;s discretion.</li>
                                        <li>During open box deliveries, while accepting your order, if you received a different or a damaged product, you will be given a refund (on the spot refunds for cash-on-delivery orders). Once you have accepted an open box delivery, no return request will be processed, except for manufacturing defects.</li>
                                    </ol>
                                    <p>'In case the product was not delivered and you received a delivery confirmation email/SMS, report the issue within 7 days from the date of delivery confirmation for the seller to investigate.'</p>
                                    <p><strong>&nbsp;</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default ReturnPolicy