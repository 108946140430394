import React from "react";
import { useGetCart, useRemoveFromCart } from "../../shared/hooks/UseCart";
import { URI } from "../../domain/constant";
import { Link } from "react-router-dom";
import { useState } from "react";

function Cart() {
  const [cart, total] = useGetCart();
  const [removeFromCart] = useRemoveFromCart();

  return (
    <>
      <div
        className="offcanvas offcanvas-end "
        data-bs-scroll="true"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header bg-section-2">
          <h5 className="mb-0 fw-bold" id="offcanvasRightLabel">
            {cart && cart.length} items in the cart
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <div className="cart-list">
            {cart && cart.length > 0 ? (
              cart &&
              cart.map((item) => {
                return (
                  <>
                    <div className="d-flex align-items-center gap-3">
                      <div className="bottom-product-img">
                        <Link to={`/product/${item.slug}`}>
                          <img src={`${URI}${item.image}`} width={60} alt />
                        </Link>
                      </div>

                      <div className>
                        <Link to={`/product/${item.slug}`}>
                          <h6 className="mb-0 fw-light mb-1">{item.name}</h6>
                        </Link>
                        <p className="mb-0">
                          <strong>
                            {" "}
                            {item.quantity} x ₹{item.sale_price}
                          </strong>
                        </p>
                      </div>
                      <div className="ms-auto fs-5">
                        <a
                          href="javascript:"
                          onClick={() => removeFromCart(item.slug)}
                          className="offcanvas-cart-item-delete link-dark"
                        >
                          <i className="bi bi-trash" />
                        </a>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })
            ) : (
              <>
                <img
                  src="/assets/images/wishlist-icon/wishlist-empty.jpg"
                  alt=""
                  className="wishlist-empty-img"
                />
                <div className="wishlist-empty-text">
                  <h4>Shopping Cart Empty!</h4>
                  <p>Lets add some items.</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="offcanvas-footer p-3 border-top">
          <div className="d-grid">
            <Link
              to={"/cart"}
              className="btn btn-lg btn-dark btn-ecomm px-5 py-3"
            >
              Cart
            </Link>
          </div>
        </div>

        <div className="offcanvas-footer p-3 border-top">
          <div className="d-grid">
            <Link
              to={"/checkout"}
              className="btn btn-lg btn-dark btn-ecomm px-5 py-3"
            >
              Checkout
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
