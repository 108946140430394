import React from "react";
import { useState } from "react";
import Banner from "../../components/banner/Banner";
import BestSeller from "../../components/bestseller/BestSeller";
import Blog from "../../components/blog/Blog";
import Brand from "../../components/brand/Brand";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import FeaturedBanner from "../../components/featured_banner/FeaturedBanner";
import Featured from "../../components/featured_product/Featured";
import LatestProduct from "../../components/latest_product/LatestProduct";
import NewArrival from "../../components/newarrival/NewArrival";
import NewsLetter from "../../components/newsletter/NewsLetter";
import OfferBanner from "../../components/offerbanner/OfferBanner";
import Testimonial from "../../components/testimonial/Testimonial";
import TopCategory from "../../components/topcategory/TopCategory";
import Trending from "../../components/trending/Trending";
import WhatWeOffer from "../../components/whatweoffer/WhatWeOffer";
import { useSelectAllHomepage } from "../../shared/hooks/UseHomepage";
import Skeleton from "react-loading-skeleton";
function Home() {
  const [data] = useSelectAllHomepage();
  const { all_homepages, all_homepages_loading } = data;
  console.log(all_homepages);
  const [modalData, setModalData] = useState(null);
  const [is_modal_open, setIs_modal_open] = useState(false);

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner />
        {all_homepages_loading && (
          <div>
            <img src="/assets/images/placeholder-banner.png" />
          </div>
        )}
        {all_homepages &&
          all_homepages.map((item, index) => {
            return (
              <>
                {item.position === 1 && <FeaturedBanner content={item} />}
                {item.position === 2 && (
                  <Featured
                    content={item}
                    setIs_modal_open={setIs_modal_open}
                    is_modal_open={is_modal_open}
                    setModalData={setModalData}
                    modalData={modalData}
                  />
                )}
                {item.position === 3 && (
                  <NewArrival
                    content={item}
                    setIs_modal_open={setIs_modal_open}
                    is_modal_open={is_modal_open}
                    setModalData={setModalData}
                    modalData={modalData}
                  />
                )}
                {item.position === 4 && (
                  <BestSeller
                    content={item}
                    setIs_modal_open={setIs_modal_open}
                    is_modal_open={is_modal_open}
                    setModalData={setModalData}
                    modalData={modalData}
                  />
                )}
                {item.position === 5 && (
                  <Trending
                    content={item}
                    setIs_modal_open={setIs_modal_open}
                    is_modal_open={is_modal_open}
                    setModalData={setModalData}
                    modalData={modalData}
                  />
                )}
                {item.position === 6 && <WhatWeOffer content={item} />}
                {item.position === 7 && <OfferBanner content={item} />}
                {item.position === 8 && <TopCategory content={item} />}
                {item.position === 9 && <Brand content={item} />}
              </>
            );
          })}

        <Testimonial />
        <NewsLetter />
        <Blog />
      </div>

      <Footer />
    </>
  );
}

export default Home;
