import React from "react";
import { URI } from "../../domain/constant";
import { useSelectAllBanner } from "../../shared/hooks/UseBanner";
import Slider from "react-slick";
import NextArrowComponent from "../slider/NextArrowComponent";
import PreArrowComponent from "../slider/PreArrowComponent";
import { Link } from "react-router-dom";
import Image from "../common/Image";

function Banner() {
  const [data] = useSelectAllBanner();
  const { all_banners, all_banners_loading } = data;
  const settings = {
    dots: true,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrowComponent />,
    prevArrow: <PreArrowComponent />,
  };
  return (
    <div>
      <section>
        {all_banners && (
          <Slider {...settings}>
            {all_banners &&
              all_banners.map((item) => {
                return (
                  <div>
                    <Link to={item.link ? item.link : "/shop"}>
                      <Image
                        src={`${URI}${item.image}`}
                        style={{ width: "100%" }}
                        placeHolderImage="/assets/images/placeholder-banner.png"
                      />
                    </Link>
                  </div>
                );
              })}
          </Slider>
        )}
      </section>
    </div>
  );
}

export default Banner;
